var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.section.main_information')))])],1)]),_c('b-card-body',[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"config"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.config')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.config'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.notificationConfigList,"label":"email_subject","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.elementData.noti_config_id),callback:function ($$v) {_vm.$set(_vm.elementData, "noti_config_id", $$v)},expression:"elementData.noti_config_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"noti_medium"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.noti_medium')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.noti_medium'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.notificationMediumList,"label":"value","value":"id","reduce":function (option) { return option.id; },"multiple":""},model:{value:(_vm.mediumsSelected),callback:function ($$v) {_vm.mediumsSelected=$$v},expression:"mediumsSelected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_vm._l((_vm.mediumList),function(medium){return _c('b-row',{key:medium.id},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"medium"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.contact'))+" - "+_vm._s(medium.value))]),_c('validation-provider',{attrs:{"name":medium.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":medium.options,"label":"data","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.mediumsNames[medium.name]),callback:function ($$v) {_vm.$set(_vm.mediumsNames, medium.name, $$v)},expression:"mediumsNames[medium.name]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }