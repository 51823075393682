<template>
  <div>
    <b-form-group :label-for="elementLabel">
      <feather-icon
        icon="StarIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ elementLabel }}</span><span
        v-if="elementRequired"
        class="text-danger"
      >*</span>

      <validation-provider
        #default="{ errors }"
        :name="elementLabel"
        :rules="rules"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid':null"
          autocomplete="off"
        >
          <flat-pickr
            :id="elementLabelInput"
            v-model="elementData"
            class="form-control"
            :config="config"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              class="mb-0 mb-sm-0 mr-0 mr-sm-1"
              data-toggle
            >
              <feather-icon icon="CalendarIcon" />
            </b-button>
            <b-button
              variant="primary"
              class="mb-0 mb-sm-0 mr-0 mr-sm-0"
              data-clear
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormGroup, BFormDatepicker, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import 'flatpickr/dist/flatpickr.css'

export default {
  components: {
    BButton, BInputGroup, BInputGroupAppend, BFormGroup, ValidationProvider, flatPickr,
  },
  props: {
    rootElementData: {
      type: Object,
      required: true,
    },
    elementLabel: {
      type: String,
      required: true,
    },
    elementField: {
      type: String,
      required: true,
    },
    elementRequired: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      elementLabelInput: '',
      config: {
        static: true,
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'd/m/Y h:i K',
        locale: Spanish, // locale for this instance only
        allowInput: true,
        enableTime: true,
      },
    }
  },
  computed: {
    rules: {
      get() {
        console.log('rules')
        return this.elementRequired ? 'required' : undefined
      },
    },
    elementData: {
      get() {
        if (this.rootElementData[this.elementField]) {
          return this.rootElementData[this.elementField]
        }
        return null
      },
      set(newValue) {
        this.rootElementData[this.elementField] = newValue
      },
    },
  },
  mounted() {
    this.config = {
      wrap: true, // set wrap to true only when using 'input-group'
      dateFormat: 'd/m/Y h:i K',
      locale: Spanish, // locale for this instance only
      allowInput: true,
      enableTime: true,
    }
    this.elementLabelInput = `${this.elementLabel}-input`
  },
}
</script>
