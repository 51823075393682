<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary" />
        <span class="d-none d-sm-inline">{{
          $t(resourcesElementName + '.section.contacts')
        }}</span>
      </div>
    </b-card-header>
    <b-card-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-button
              variant="primary"
              @click="addContact">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="elementData.guardians"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(resourcesElementName + '.search.not_found')">
        <!-- column: name -->
        <template #cell(name)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.name }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(contact_job)="data"> {{ data.item.job }} </template>.
        <template #cell(contact_email)="data"> {{ data.item.email }} </template
        >.
        <template #cell(contact_number)="data">
          {{ data.item.phone }}
        </template>
        <template #cell(status)="data">
          <h2 class="inline">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize mr-0 mt-50">
              {{ resolveStatusMessage(data.item.status) }}
            </b-badge>
          </h2>
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="get_edit_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="editContact(data.item)">
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-if="data.item.status"
              :id="get_deactivate_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="deactivateContact(data.item.id)">
              <feather-icon icon="DeleteIcon" />
            </b-button>

            <b-button
              v-else
              :id="get_reactivate_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="reactivateContact(data.item.id)">
              <feather-icon icon="PlusIcon" />
            </b-button>
            <!-- ? EDIT -->
            <b-tooltip
              :target="get_edit_button_id(data.item.id)"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
            <!-- ? DEACTIVATE -->
            <b-tooltip
              v-if="data.item.status"
              :target="get_deactivate_button_id(data.item.id)"
              :title="$t('actions.deactivate')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
            <!-- ? REACTIVATE -->
            <b-tooltip
              v-else
              :target="get_reactivate_button_id(data.item.id)"
              :title="$t('actions.reactivate')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <div>
        <b-modal
          id="modal-1"
          size="lg"
          hide-footer
          :title="$t(resourcesElementName + '.object.new_title')">
          <template>
            <guardian-edit-tab
              :new-element="newContact"
              :element-data="contactData"
              class="mt-2 pt-75" />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BBadge,
  BTable,
  BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import GuardianEditTab from './EditGuardian.vue'

export default {
  components: {
    BTooltip,
    GuardianEditTab,
    BModal,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCardHeader,
    BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      resourcesElementName: '',
      required,
      contactData: {},
      newContact: true,
    }
  },
  beforeDestroy() {
    this.$root.$off('save-guardian')
  },
  mounted() {
    this.$root.$on('save-guardian', (guardian_data) => {
      const index = this.elementData.guardians.findIndex(
        (element) => element.id == guardian_data.id
      )
      if (index < 0) this.elementData.guardians.push(guardian_data)
    })
    this.resourcesElementName = 'contact'
    this.tableColumns = [
      {
        key: 'name',
        label: this.$t(`${this.resourcesElementName}.object.name`),
        sortable: true,
      },
      {
        key: 'contact_job',
        label: this.$t(`${this.resourcesElementName}.object.contact_job`),
        sortable: true,
      },
      {
        key: 'contact_email',
        label: this.$t(`${this.resourcesElementName}.object.contact_email`),
        sortable: true,
      },
      {
        key: 'contact_number',
        label: this.$t(`${this.resourcesElementName}.object.contact_number`),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t(`${this.resourcesElementName}.object.status`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.resourcesElementName}.object.actions`),
      },
    ]
  },
  methods: {
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    get_deactivate_button_id(key) {
      return `deactivate_button${key}`
    },
    get_reactivate_button_id(key) {
      return `reactivate_button${key}`
    },
    editContact(item) {
      this.contactData = item
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    addContact() {
      this.contactData = { id: Math.floor(Math.random() * 100) * -1, status: 1 }
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    deactivateContact(id) {
      const element = this.elementData.guardians.find(
        (element) => element.id == id
      )
      const index = this.elementData.guardians.indexOf(element)
      element.status = 0
      this.elementData.guardians.splice(index, 1, element)
    },
    reactivateContact(id) {
      const element = this.elementData.guardians.find(
        (element) => element.id == id
      )
      const index = this.elementData.guardians.indexOf(element)
      element.status = 1
      this.elementData.guardians.splice(index, 1, element)
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    },
    resolveStatusVariant(status) {
      if (status > 0) return 'success'
      return 'warning'
    },
    resolveStatusMessage(status) {
      if (status > 0)
        return this.$t(`${this.resourcesElementName}.statusValue.active`)
      return this.$t(`${this.resourcesElementName}.statusValue.inactive`)
    },
  },
}
</script>

<style></style>
