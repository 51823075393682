// src/services/axiosClient.js
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import handlerUpdateObjectById from '@/handlers/handlerUpdateObjectById'
import { cleanupExpiredData, getIndexedDB, saveIndexedDB } from './indexedDBService'

const getIdentify = async () => {
  // Generate a unique identifier for the browser
  const indexedDB_Id = await getIndexedDB('browser-identifier')
  if (indexedDB_Id) {
    return indexedDB_Id.id
  }
  const identifier = uuidv4()
  await saveIndexedDB('browser-identifier', { id: identifier })
  return identifier
}

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
})

axiosClient.interceptors.request.use(async config => {
  config.headers['X-Identification'] = await getIdentify()
  await cleanupExpiredData(config.url)
  const dataIndexedDB = await getIndexedDB(config.url)
  if (dataIndexedDB) {
    config.headers['If-None-Match'] = dataIndexedDB.etag
  } else {
    delete config.headers['If-None-Match']
    config.headers['Cache-Control'] = 'no-cache'
  }
  return config
}, error => {
  return Promise.reject(error)
})

axiosClient.interceptors.response.use(async response => {
  const etag = response.headers['etag']
  const url = response.config.url
  const storageCache = await getIndexedDB(url) || {}
  if (etag && storageCache.etag) {
    storageCache.data = handlerUpdateObjectById(storageCache.data, response.data)
  } else {
    storageCache.data = response.data
  }

  await saveIndexedDB(url, { etag, data: storageCache.data })
  return { ...response, data: storageCache.data }
}, error => {
  if (error.response && error.response.status === 304) {
    return getIndexedDB(error.response.config.url).then(dataIndexedDB => {
      if (dataIndexedDB) {
        return Promise.resolve({
          ...error.response,
          data: dataIndexedDB.data,
        })
      }
      return Promise.reject(error)
    })
  }
  return Promise.reject(error)
})

export default axiosClient