<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.process_data') }}</span>
      </div>
    </b-card-header>
    <b-card-body v-if="elementData && resourcesElementName">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="addNotification"
            >
              <span class="text-nowrap">{{ $t(resourcesElementName + '.object.add') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="elementData.notifications"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(resourcesElementName + '.search.not_found')"
      >
        <!-- column: name -->
        <template #cell(data)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.data }}
          </b-badge>
        </template>
        <template #cell(noti_medium)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ getTypeValue(data.item.noti_medium) }}
          </b-badge>
        </template>
        <template #cell(contact)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ getContact(data.item.contact) }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <h2 class="inline">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize mr-0 mt-50"
            >
              {{ resolveStatusMessage(data.item.status) }}
            </b-badge>
          </h2>
        </template>
        <template #cell(actions)="data">
          <b-col>
            <b-button
              :id="get_edit_button_id(data.item.id)"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="editNotification(data.item)"
            >
              <feather-icon icon="EditIcon" />

            </b-button>
            <b-button
              :id="get_send_button_id(data.item.id)"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="sendNotification(data.item)"
            >
              <feather-icon icon="SendIcon" />

            </b-button>

            <b-button
              v-if="data.item.status"
              :id="get_deactivate_button_id(data.item.id)"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="deactivateNotification(data.item.id)"
            >
              <feather-icon icon="DeleteIcon" />
            </b-button>

            <b-button
              v-else
              :id="get_reactivate_button_id(data.item.id)"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="reactivateNotification(data.item.id)"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>

            <b-tooltip
              :target="get_edit_button_id(data.item.id)"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.edit') }}</span>
            </b-tooltip>
            <b-tooltip
              :target="get_send_button_id(data.item.id)"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.resend') }}</span>
            </b-tooltip>
            <b-tooltip
              v-if="data.item.status"
              :target="get_deactivate_button_id(data.item.id)"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.deactivate') }}</span>
            </b-tooltip>
            <b-tooltip
              v-else
              :target="get_reactivate_button_id(data.item.id)"
              triggers="hover"
            >
              <span class="align-middle ml-50">{{ $t('actions.reactivate') }}</span>
            </b-tooltip>
          </b-col>
        </template>

      </b-table>
      <div>
        <b-modal
          id="modal-notification"
          size="lg"
          hide-footer
          :title="$t(resourcesElementName + '.object.new_title')"
        >
          <template>
            <notification-edit-tab
              :new-element="newElement"
              :element-data="notificationData"
              :notification-config-list="notificationConfigList"
              :notification-medium-list="notificationMediumList"
              :contact-list="contactList"
              class="mt-2 pt-75"
            />
          </template>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-add-notifications"
          size="lg"
          hide-footer
          :title="$t(resourcesElementName + '.object.new_title')"
        >
          <template>
            <add-notifications-tab
              :new-element="newElement"
              :element-data="notificationData"
              :notification-config-list="notificationConfigList"
              :notification-medium-list="notificationMediumList"
              :contact-list="contactList"
              class="mt-2 pt-75"
            />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BTooltip, BCard, BDropdown, BDropdownItem, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BTable, BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import NotificationEditTab from './EditNotification.vue'
import AddNotificationsTab from './AddNotifications.vue'

export default {
  components: {
    BTooltip, NotificationEditTab, BDropdown, BDropdownItem, BModal, BTable, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver, AddNotificationsTab,
  },
  props: {
    /** in this case the elementData is the step specifically */
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    notificationConfigList: {
      type: Array,
      required: true,
    },
    notificationMediumList: {
      type: Array,
      required: true,
    },
    contactList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [
      ],
      resourcesElementName: 'notification',
      required,
      notificationData: {},
    }
  },
  beforeDestroy() {
    this.$root.$off('save-notification')
  },
  mounted() {
    this.$root.$on('save-notification', notificationData => {
      notificationData.step_id = this.elementData.id
      const index = this.elementData.notifications.findIndex(element => element.id == notificationData.id)
      if (index < 0) this.elementData.notifications.push(notificationData)
    })
    this.tableColumns = [
      { key: 'noti_medium', label: this.$t(`${this.resourcesElementName}.object.noti_medium`), sortable: true },
      { key: 'contact', label: this.$t(`${this.resourcesElementName}.object.contact`), sortable: true },
      { key: 'status', label: this.$t(`${this.resourcesElementName}.object.status`), sortable: true },
      { key: 'actions', label: this.$t(`${this.resourcesElementName}.object.actions`) },
    ]
  },
  methods: {
    getTypeValue(element) {
      return element ? element.value : ''
    },
    getContact(element) {
      return element ? element.data : ''
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    get_send_button_id(key) {
      return `send_button${key}`
    },
    get_deactivate_button_id(key) {
      return `deactivate_button${key}`
    },
    get_reactivate_button_id(key) {
      return `reactivate_button${key}`
    },
    editNotification(item) {
      this.notificationData = item
      this.$root.$emit('bv::show::modal', 'modal-notification')
    },
    sendNotification(item) {
      console.log(item)
      this.$root.$emit('send-notification', item)
    },
    addNotification() {
      this.notificationData = { id: 0, status: 1 }
      this.$root.$emit('bv::show::modal', 'modal-add-notifications')
    },
    deactivateNotification(id) {
      const element = this.elementData.notifications.find(element => element.id == id)
      const index = this.elementData.notifications.indexOf(element)
      element.status = 0
      this.elementData.notifications.splice(index, 1, element)
    },
    reactivateNotification(id) {
      const element = this.elementData.notifications.find(element => element.id == id)
      const index = this.elementData.notifications.indexOf(element)
      element.status = 1
      this.elementData.notifications.splice(index, 1, element)
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-notification')
      this.$root.$emit('bv::hide::modal', 'modal-add-notifications')
    },
    resolveStatusVariant(status) {
      if (status > 0) return 'success'
      return 'warning'
    },
    resolveStatusMessage(status) {
      if (status > 0) return this.$t(`${this.resourcesElementName}.statusValue.active`)
      return this.$t(`${this.resourcesElementName}.statusValue.inactive`)
    },
  },
}
</script>

<style>

</style>
