<template>
  <b-card>
    <b-card-body>
    
      <validation-observer 
        ref="elementForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="save"
        >
          <element-edit-tab
            :new-element="newElement"
            :element-data="elementData"
            :country-list= "countryList"
            :contact-type-list="contactTypeList"
            :department-list= "departmentList"
            :city-list="cityList"
            :sex-list="sexList"
            :genre-list="genreList"
            :document-type-list="documentTypeList"
            :employment-list="employmentList"
            :arl-list="arlList"
            :eps-list="epsList"
            :element-logo="logo"
            :is-modal="true"
            resources-element-name="patient"
            :is-patient="true"
            :relationship-list= "relationshipList"
            :population-zone-list="populationZoneList"
            :element-avatar="elementAvatar"
            class="mt-2 pt-75"
          />
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import ElementEditTab from '../../../patient/edit/EditCard.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ElementEditTab, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver 
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    sexList: {
      type: Array,
      required: true,
    },
    genreList: {
      type: Array,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    employmentList: {
      type: Array,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    epsList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true
    },
    elementAvatar: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'guardian',
      logo: ''
    }
  },
  methods: {
  }
}
</script>

<style>

</style>
