<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Agendamiento
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event');"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="Doctor"
              rules="required"
            >

              <b-form-group
                label="Doctor"
                label-for="doctor"
                :state="getValidationState(validationContext)"
              >
                <b-form-select
                  v-model="eventLocal.doctor_id"
                  :options="calendarOptions"
                  value-field="id"
                  text-field="full_name"
                  disabled-field="notEnabled"
                  :disabled="true"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >

              <b-form-group
                label="Tipo"
                label-for="type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.type_id"
                  :options="dateTypeList"
                  label="value"
                  value="id"
                  :reduce="option => option.id"
                  :placeholder="$t('list.defaultOption')"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <date-time-picker-calendar
              :element-required="true"
              element-field="start_date"
              :root-element-data="eventLocal"
              element-label="Fecha de Inicio"
            />
            <date-time-picker-calendar
              :element-required="true"
              element-field="end_date"
              :root-element-data="eventLocal"
              element-label="Fecha de Fin"
            />

            <!-- Event URL -->
            <validation-provider
              #default="validationContext"
              name="Event URL"
              rules="url"
            >

              <b-form-group
                label="Event URL"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.eventUrl"
                  type="url"
                  :state="getValidationState(validationContext)"
                  :disabled="true"
                  placeholder="asignado automáticamente"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Textarea -->
            <b-form-group
              label="Descripción"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.description"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Guardar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormSelect, BFormGroup, BFormInput, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import DateTimePickerCalendar from '@/views/core/DateTimePickerCalendar.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import useCalendarEventHandler from './useCalendarEventHandler'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DateTimePickerCalendar,
    BButton,
    BSidebar,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      full_name: '',
      url,
      loading: true,
    }
  },
  watch: {
    'eventLocal.start_date': function (newValue, oldValue) {
      // doesn't recalculates if is loading
      const nv = moment(newValue, 'DD/MM/YYYY h:mm A')
      const ov = moment(oldValue, 'DD/MM/YYYY h:mm A')
      if (this.loading || !oldValue || newValue == oldValue || nv.isSame(ov)) {
        this.loading = false
      } else {
        const type = this.dateTypeList.find(element => element.id === this.eventLocal.type_id)
        let mom = moment(newValue, 'DD/MM/YYYY h:mm A')
        mom = mom.add(30, 'minutes').format('DD/MM/YYYY h:mm A')
        this.eventLocal.end_date = mom
      }
    },
  },
  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      dateTypeList,

      // UI
      onSubmit,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      dateTypeList,
      onSubmit,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
