<template>
  <b-card>
    <b-card-body>
    
      <validation-observer 
        ref="elementForm"
        #default="{ invalid }"
        >
        <b-form
          @submit.prevent="save"
        >
          <element-edit-tab
            :new-element="newElement"
            :element-data="elementData"
            :department-list= "departmentList"
            :city-list="cityCompanyList"
            :city-contact-list="cityContactList"
            :city-headquarter-list="cityHeadquarterList"
            :element-logo="logo"
            :is-modal="true"
            module-name="apps-enterprise"
            :resources-element-name="resourcesElementName"
            :contact-type-list="contactTypeList"
            :supplier-type-list="supplierTypeList"
            class="mt-2 pt-75"
          />
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect
} from 'bootstrap-vue'
import { required } from '@validations'
import ElementEditTab from '@/views/company/edit/EditCard.vue'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ElementEditTab, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver 
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityCompanyList: {
      type: Array,
      required: true,
    },
    cityContactList: {
      type: Array,
      required: true,
    },
    supplierTypeList: {
      type: Array,
      required: true,
    },
    cityHeadquarterList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'enterprise',
      logo: ''
    }
  },
  methods: {
    fetchLogo(logo_id) {
      store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetchImage', { id: logo_id })
      .then(response => { 
        this.logo = response.data.img 
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.logo)
          logo = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
