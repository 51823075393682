import store from '@/store'

import { computed } from '@vue/composition-api'

export default function useCalendarSidebar(emit) {
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)
  const dateTypeList = computed(() => store.state.calendar.dateTypeList)
  const case_id = computed(() => store.state.calendar.case_id)

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed({
    get: () => store.state.calendar.selectedCalendars,
    set: val => {
      store.commit('calendar/SET_SELECTED_DOCTORS', val)
    },
  })
  const selectedDateType = computed({
    get: () => store.state.calendar.selectedDateType,
    set: val => {
      store.commit('calendar/SET_SELECTED_DATE_TYPE', val)
    },
  })

  // ------------------------------------------------
  // checkAll
  // ------------------------------------------------
  const checkAll = computed({
    /*
      GET: Return boolean `true` => if length of options matches length of selected filters => Length matches when all events are selected
      SET: If value is `true` => then add all available options in selected filters => Select All
           Else if => all filters are selected (by checking length of both array) => Empty Selected array  => Deselect All
    */
    get: () => {
      return selectedCalendars.value.length === calendarOptions.value.length
    },
    set: val => {
      if (val) {
        selectedCalendars.value = calendarOptions.value.map(i => i.id)
      } else if (selectedCalendars.value.length === calendarOptions.value.length) {
        selectedCalendars.value = []
      }
    },
  })

  return {
    calendarOptions,
    selectedCalendars,
    selectedDateType,
    checkAll,
    dateTypeList,
    case_id,
  }
}
