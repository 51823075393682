<template>
  <div>
    <b-form
      overlay
      @submit.prevent="save">
      <b-overlay
        :show="submitting"
        no-wrap
        fixed
        rounded="sm">
        <template
          id="template_overlay"
          #overlay>
          <div class="d-flex align-items-center" />
          <b-spinner
            small
            type="grow"
            variant="secondary" />
          <b-spinner
            type="grow"
            variant="dark" />
          <b-spinner
            small
            type="grow"
            variant="secondary" />
          <div class="d-flex align-items-center" />
        </template>
      </b-overlay>
      <validation-observer
        ref="elementForm"
        #default="{ invalid }">
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.section.basic_info')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.case_number')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.case_number')">
                    <b-form-input
                      id="name"
                      v-model="elementData.case_number"
                      maxlength="3"
                      :disabled="true" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="internal_code">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.internal_code')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.internal_code')">
                    <b-form-input
                      id="internal_code"
                      v-model="elementData.internal_code"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="case_type">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.case_type')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.case_type')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.case_type_id"
                        :options="caseTypeList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="arl">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.arl')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.arl')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.arl_id"
                        :options="arlList"
                        label="name"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card v-if="elementData.patient">
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.section.patient_info')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="3"
                md="3">
                <b-form-group label-for="patient_document_type">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_document_type')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(resourcesElementName + '.object.patient_document_type')
                    "
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.patient.document_type_id"
                        :options="documentTypeList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="4"
                xl="4">
                <b-form-group label-for="patient_identity">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_identity')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(resourcesElementName + '.object.patient_identity')
                    "
                    :rules="ruleRequired">
                    <b-form-input
                      id="name"
                      v-model="elementData.patient.identity"
                      :disabled="patientIsDisabled"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="actions">
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.actions')
                  }}</span>
                  <b-row>
                    <b-button
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="searchPatient()">
                      {{ $t('actions.search') }}
                    </b-button>
                    <b-button
                      v-if="patientSearched"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="showPatientModal()">
                      {{ $t('actions.edit') }}
                    </b-button>
                    <b-button
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="changePatient()">
                      {{ $t('actions.change') }}
                    </b-button>
                  </b-row>
                </b-form-group>
              </b-col>
              <!-- Media -->
              <b-col
                cols="2"
                xl="2">
                <b-media>
                  <b-col
                    class="text-center mb-1"
                    cols="12"
                    md="12">
                    <b-img
                      :src="avatar"
                      class="border"
                      fluid
                      text=""
                      variant="light-success" />
                  </b-col>
                </b-media>
              </b-col>
            </b-row>

            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="patient_first_name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_first_name')
                  }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.patient.first_name"
                    :disabled="true" />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="patient_second_name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_second_name')
                  }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.patient.second_name"
                    :disabled="true" />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="patient_first_surname">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_first_surname')
                  }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.patient.first_surname"
                    :disabled="true" />
                </b-form-group>
              </b-col>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="patient_second_surname">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.patient_second_surname')
                  }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.patient.second_surname"
                    :disabled="true" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card v-if="elementData.company">
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.section.company_info')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="3"
                xl="3">
                <b-form-group label-for="company_nit">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.company_nit')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.company_nit')"
                    :rules="ruleRequired">
                    <b-form-input
                      id="name"
                      v-model="elementData.company.nit"
                      maxlength="11" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                xl="4">
                <b-form-group label-for="actions">
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.actions')
                  }}</span>
                  <b-row>
                    <b-button
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="searchCompany()">
                      {{ $t('actions.search') }}
                    </b-button>
                    <b-button
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      @click="showCompanyModal()">
                      {{ $t('actions.edit') }}
                    </b-button>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col
                cols="5"
                xl="5">
                <b-form-group label-for="company_name">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.company_name')
                  }}</span>
                  <b-form-input
                    id="name"
                    v-model="elementData.company.name"
                    :disabled="true" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.section.accident_info')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="6"
                md="6"
                lg="6">
                <date-picker
                  :element-required="true"
                  element-field="accident_date"
                  :root-element-data="elementData"
                  :element-label="
                    $t(resourcesElementName + '.object.accident_date')
                  " />
              </b-col>
              <b-col
                cols="6"
                md="6"
                lg="6">
                <date-picker
                  :element-required="true"
                  element-field="report_date"
                  :root-element-data="elementData"
                  :element-label="
                    $t(resourcesElementName + '.object.report_date')
                  " />
              </b-col>
            </b-row>

            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="4"
                xl="4">
                <b-form-group label-for="accident_place">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.accident_place')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.accident_place')">
                    <b-form-input
                      id="name"
                      v-model="elementData.accident_place"
                      maxlength="60" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
                md="4">
                <b-form-group label-for="department">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.department')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.department')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.department_id"
                        :options="departmentList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents"
                        @input="onDepartmentChange" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4">
                <b-form-group label-for="city">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.city')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.city')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.city_id"
                        :options="cityCaseList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :filter-by="filterWithoutAccents"
                        :placeholder="$t('list.defaultOption')" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="4"
                md="4">
                <b-form-group label-for="known_source">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.known_source')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.known_source')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.known_source_id"
                        :options="booleanNAList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        :filter-by="filterWithoutAccents"
                        @input="change_known_source" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div
              id="source_fields"
              class="hidden-div">
              <b-row>
                <b-col
                  cols="4"
                  md="4">
                  <b-form-group label-for="source_document_type">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_document_type')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(
                          resourcesElementName + '.object.source_document_type'
                        )
                      ">
                      <div class="v-select-wrapper">
                        <v-select
                          v-model="elementData.source_document_type_id"
                          :options="documentTypeList"
                          label="value"
                          value="id"
                          :reduce="(option) => option.id"
                          :placeholder="$t('list.defaultOption')"
                          :filter-by="filterWithoutAccents" />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Right Col: Table -->
                <b-col
                  cols="4"
                  xl="4">
                  <b-form-group label-for="source_identity">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_identity')
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(resourcesElementName + '.object.source_identity')
                      ">
                      <b-form-input
                        id="name"
                        v-model="elementData.source_identity"
                        maxlength="20" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- Right Col: Table -->
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="source_first_name">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_first_name')
                    }}</span>
                    <b-form-input
                      id="name"
                      v-model="elementData.source_first_name"
                      maxlength="20" />
                  </b-form-group>
                </b-col>
                <!-- Right Col: Table -->
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="source_second_name">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_second_name')
                    }}</span>
                    <b-form-input
                      id="name"
                      v-model="elementData.source_second_name"
                      maxlength="20" />
                  </b-form-group>
                </b-col>
                <!-- Right Col: Table -->
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="source_first_surname">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_first_surname')
                    }}</span>
                    <b-form-input
                      id="name"
                      v-model="elementData.source_first_surname"
                      maxlength="20" />
                  </b-form-group>
                </b-col>
                <!-- Right Col: Table -->
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="source_second_surname">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_second_surname')
                    }}</span>
                    <b-form-input
                      id="name"
                      v-model="elementData.source_second_surname"
                      maxlength="20" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="3"
                  xl="3">
                  <date-picker
                    :element-required="false"
                    element-field="source_birthdate"
                    :root-element-data="elementData"
                    :element-label="
                      $t(resourcesElementName + '.object.source_birthdate')
                    " />
                </b-col>
                <b-col
                  cols="3"
                  xl="3">
                  <b-form-group label-for="source_age">
                    <feather-icon
                      icon="StarIcon"
                      class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.source_age')
                    }}</span>
                    <b-form-input
                      id="source_age"
                      v-model="source_age"
                      maxlength="3"
                      :disabled="true" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.source_positive')
                }}</span>
              </b-row>
              <b-row>
                <b-col
                  cols="2"
                  md="2">
                  <b-form-group label-for="element-status">
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.hiv')
                    }}</span>
                    <b-form-checkbox
                      v-model="elementData.source_positive_hiv"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      value="1"
                      unchecked-value="0" />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                  md="2">
                  <b-form-group label-for="element-status">
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.vhc')
                    }}</span>
                    <b-form-checkbox
                      v-model="elementData.source_positive_vhc"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      value="1"
                      unchecked-value="0" />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                  md="2">
                  <b-form-group label-for="element-status">
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.vhb')
                    }}</span>
                    <b-form-checkbox
                      v-model="elementData.source_positive_vhb"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      value="1"
                      unchecked-value="0" />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                  md="2">
                  <b-form-group label-for="element-status">
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + '.object.syphilis')
                    }}</span>
                    <b-form-checkbox
                      v-model="elementData.source_positive_syphilis"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      value="1"
                      unchecked-value="0" />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>

        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.section.authorization_codes')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="6"
                xl="6">
                <b-form-group label-for="case_initial_valuation_auth_code">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(
                      resourcesElementName +
                        '.object.case_initial_valuation_auth_code'
                    )
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(
                        resourcesElementName +
                          '.object.case_initial_valuation_auth_code'
                      )
                    ">
                    <b-form-input
                      id="name"
                      v-model="elementData.initial_valuation_auth_code"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                xl="6">
                <b-form-group label-for="management_auth_code">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.management_auth_code')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(resourcesElementName + '.object.management_auth_code')
                    ">
                    <b-form-input
                      id="name"
                      v-model="elementData.management_auth_code"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="6"
                xl="6">
                <b-form-group label-for="case_control_auth_code">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.case_control_auth_code')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(
                        resourcesElementName + '.object.case_control_auth_code'
                      )
                    ">
                    <b-form-input
                      id="name"
                      v-model="elementData.control_auth_code"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="6"
                xl="6">
                <b-form-group label-for="case_laboratories_code">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.case_laboratories_code')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="
                      $t(
                        resourcesElementName + '.object.case_laboratories_code'
                      )
                    ">
                    <b-form-input
                      id="name"
                      v-model="elementData.laboratories_code"
                      maxlength="20" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.object.resumen')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="4"
                xl="4">
                <b-form-group label-for="case_status">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.case_status')
                  }}</span
                  ><span
                    v-if="!try_contact_failed"
                    class="text-danger"
                    >*</span
                  >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.case_status')"
                    :rules="ruleRequired">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.case_status_id"
                        :options="internalCaseStatusList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :disabled="true" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="4">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(resourcesElementName + '.object.last_consult_type')
                }}</span>
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.last_consult_type_id"
                    :options="consultTypeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :disabled="true" />
                </div>
              </b-col>
              <b-col cols="4">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold info_title">{{
                  $t(resourcesElementName + '.object.follow_type')
                }}</span>
                <div class="v-select-wrapper">
                  <v-select
                    v-model="elementData.follow_type_id"
                    :options="followTypeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :disabled="true" />
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="4"
                xl="4">
                <b-form-group label-for="assigned_user">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.assigned_user')
                  }}</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.assigned_user')">
                    <div class="v-select-wrapper">
                      <v-select
                        v-model="elementData.assigned_user_id"
                        :options="userList"
                        label="name"
                        value="id"
                        :reduce="(option) => option.id"
                        :disabled="true" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Boolean field -->
              <b-col cols="4" xl="4">
                <b-form-group label-for="priority_case">
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(resourcesElementName + '.object.priority_case')}}
                  </span>
                  <b-form-checkbox
                    v-model="elementData.priority_case"
                    :name="priorityCase"
                    :rules="ruleRequired"
                    switch
                    :disabled="false">
                    {{ elementData.priority_case ? $t('boolean.yes') : $t('boolean.no') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="d-flex">
              <b-col
                cols="12"
                md="12">
                <!-- ? REVISAR -->
                <template v-if="elementData && elementData.id">
                  <process-edit-tab
                    :new-element="newElement"
                    :element-data="elementData"
                    :step-status-list="stepStatusList"
                    :step-type-list="stepTypeList"
                    :notification-config-list="notificationConfigList"
                    :notification-medium-list="notificationMediumList"
                    :contact-status-list="contactStatusList"
                    :consult-type-list="consultTypeList"
                    :contact-type-list="contactTypeList"
                    :user-list="userList"
                    :date-type-list="dateTypeList"
                    :case-status-list="caseStatusList"
                    class="mt-2 pt-75" />
                </template>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card v-if="elementData.id">
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="font-weight-bold">{{
                $t(resourcesElementName + '.object.files.section')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="9">
                <validation-provider
                  #default="{ errors }"
                  name="report"
                  rules="">
                  <!-- ? VIEW -->
                  <b-tooltip
                    target="view-report-button"
                    :title="$t(resourcesElementName + '.object.file')"
                    triggers="hover"
                    :noninteractive="true"
                    fallback-placement="counterclockwise"></b-tooltip>

                  <b-form-file
                    id="file"
                    v-model="file"
                    accept=".pdf"
                    placeholder="Seleccione el archivo o arrastrelo aquí.."
                    drop-placeholder="Suéltelo aquí..."
                    browse-text="Buscar" />
                  {{ file ? file.name : 'Archivo no seleccionado' }}
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col>
                <b-button
                  v-if="file && file.name"
                  id="cargar_archivo"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  variant="primary"
                  @click="addFile()">
                  {{ $t(resourcesElementName + '.object.files.add') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  ref="refFilesListTable"
                  class="position-relative"
                  :items="elementData.files"
                  responsive
                  :fields="tableFilesColumns"
                  primary-key="id"
                  show-empty
                  :empty-text="
                    $t(resourcesElementName + '.object.files.not_found')
                  ">
                  <template #cell(audit_date)="data">
                    {{ formatDate(data.item.audit_date) }}
                  </template>
                  <template #cell(actions)="data">
                    <b-col class="flex flex-row flex-wrap">
                      <span class="file-class">
                        <a
                          :id="`download-${data.item.id}`"
                          style="margin: 1px"
                          :href="getUrl(data.item)"
                          target="_blank">
                          {{ $t('actions.download') }}
                        </a>
                      </span>
                      <!-- ? DOWNLOAD -->
                      <b-tooltip
                        :target="`download-${data.item.id}`"
                        :title="$t('actions.download')"
                        triggers="hover"
                        :noninteractive="true"
                        fallback-placement="counterclockwise">
                      </b-tooltip>
                    </b-col>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-row class="d-flex justify-content-end">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="router().go(-1)">
            {{ $t('actions.back') }}
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="invalid && submitting">
            {{ $t('actions.save') }}
          </b-button>
        </b-row>
      </validation-observer>
    </b-form>
    <b-modal
      v-if="elementData && elementData.patient"
      id="modal-patient"
      size="xl"
      hide-footer
      :title="$t(resourcesElementName + '.object.edit_patient_title')">
      <template>
        <patient-edit-tab
          :new-element="newPatient"
          :element-data="elementData.patient"
          :country-list="countryList"
          :contact-type-list="contactTypeList"
          :department-list="departmentList"
          :city-list="cityContactList"
          :sex-list="sexList"
          :genre-list="genreList"
          :document-type-list="documentTypeList"
          :employment-list="employmentList"
          :arl-list="arlList"
          :eps-list="epsList"
          :relationship-list="relationshipList"
          :population-zone-list="populationZoneList"
          :element-avatar="elementAvatar" />
      </template>
    </b-modal>
    <b-modal
      v-if="elementData && elementData.company"
      id="modal-company"
      size="xl"
      hide-footer
      :title="$t(resourcesElementName + '.object.edit_company_title')">
      <template>
        <company-edit-tab
          :new-element="newElement"
          :element-data="elementData.company"
          :department-list="departmentList"
          :city-company-list="cityCompanyList"
          :city-contact-list="cityContactList"
          :city-headquarter-list="cityHeadquarterList"
          :supplier-type-list="supplierTypeList"
          :is-modal="false"
          :contact-type-list="contactTypeList" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BImg,
  BMedia,
  BModal,
  BOverlay,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import router from '@/router'
import vSelect from 'vue-select'
import filtersMixin from '@/libs/filtersMixin'
import ProcessEditTab from './process/EditProcess.vue'
import CompanyEditTab from './EditCompany.vue'
import PatientEditTab from './EditPatient.vue'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BTable,
    BFormFile,
    BTooltip,
    BImg,
    BMedia,
    BSpinner,
    BOverlay,
    ProcessEditTab,
    PatientEditTab,
    CompanyEditTab,
    DatePicker,
    BModal,
    BFormCheckbox,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [filtersMixin],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    listUrl: {
      type: String,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    caseTypeList: {
      type: Array,
      required: true,
    },
    caseStatusList: {
      type: Array,
      required: true,
    },
    caseTrackingTypeList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityCompanyList: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    dateTypeList: {
      type: Array,
      required: true,
    },
    supplierTypeList: {
      type: Array,
      required: true,
    },
    sexList: {
      type: Array,
      required: true,
    },
    genreList: {
      type: Array,
      required: true,
    },
    documentTypeList: {
      type: Array,
      required: true,
    },
    employmentList: {
      type: Array,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    booleanList: {
      type: Array,
      required: true,
    },
    booleanNAList: {
      type: Array,
      required: true,
    },
    epsList: {
      type: Array,
      required: true,
    },
    stepTypeList: {
      type: Array,
      required: true,
    },
    stepStatusList: {
      type: Array,
      required: true,
    },
    contactStatusList: {
      type: Array,
      required: true,
    },
    notificationMediumList: {
      type: Array,
      required: true,
    },
    relationshipList: {
      type: Array,
      required: true,
    },
    populationZoneList: {
      type: Array,
      required: true,
    },
    followTypeList: {
      type: Array,
      required: true,
    },
    elementAvatar: {
      type: String,
      required: true,
    },
    consultTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      logoUpdated: false,
      logo: null,
      newPatient: true,
      newCompany: true,
      cityContactList: [],
      cityHeadquarterList: [],
      notificationConfigList: [],
      submitting: false,
      patientIsDisabled: false,
      patientSearched: false,
      avatar: null,
      internalCaseStatusList: [],
      cityCaseList: [],
      tableFilesColumns: [],
      file: undefined,
      try_contact_failed: false,
      ruleRequired: 'required',
      priorityCase: 'priorityCase',
    }
  },
  computed: {
    source_age: {
      get() {
        if (this.elementData.source_birthdate) {
          let date = this.elementData.source_birthdate
          if (!moment.isMoment(date)) {
            date = moment(date, 'DD/MM/YYYYTHH:mm:ss')
          }
          return moment().diff(date, 'years')
        }
        return null
      },
    },
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
    'elementData.company.nit': function (newValue) {
      if (newValue === '') {
        this.elementData.company.name = ''
      }
    },
    elementAvatar(newValue, oldValue) {
      this.avatar = newValue
    },
    'elementData.known_source_id': function (newValue) {
      this.change_known_source(newValue)
    },
    'elementData.source_birthdate': function (newValue) {
      if (newValue === '') {
        this.elementData.source_birthdate = null
      }
    },
    try_contact_failed(newValue) {
      this.ruleRequired = newValue ? '' : 'required'
    },
  },
  beforeDestroy() {
    this.$root.$off('send-notification')
    this.$root.$off('save-patient')
    this.$root.$off('save-company')
    this.$root.$off('fetch-cities')
    this.$root.$off('fetch-notification-config')
    this.$root.$off('fetch-cities-headquarter')
    this.$root.$off('fetch-cities-company')
    this.$root.$off('send-notification')
    this.$root.$off('try_contact_failed')
  },
  async mounted() {
    this.tableFilesColumns = [
      {
        key: 'name',
        label: this.$t(`${this.resourcesElementName}.object.files.name`),
        sortable: true,
      },
      {
        key: 'audit_date',
        label: this.$t(`${this.resourcesElementName}.object.files.date`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.resourcesElementName}.object.actions`),
      },
    ]
    const _self = this
    this.newPatient = !this.elementData.patient || !this.elementData.patient.id
    this.newCompany = !this.elementData.company || !this.elementData.company.id
    if (!this.newElement) this.patientIsDisabled = true
    this.change_known_source(this.elementData.known_source_id)
    this.$root.$on('save-patient', (patientData) => {
      _self.elementData.patient = patientData
      this.elementAvatar = patientData.avatar
    })
    this.$root.$on('save-company', (companyData) => {
      _self.elementData.company = companyData
    })
    this.$root.$on('fetch-cities', async (department_id) => {
      _self.cityContactList = await _self.$parent.fetchCities(department_id)
    })
    this.$root.$on('fetch-notification-config', async (step_id) => {
      _self.notificationConfigList =
        await _self.$parent.fetchNotificationConfig(step_id)
    })
    this.$root.$on('fetch-cities-headquarter', async (departmentId) => {
      _self.cityHeadquarterList = await _self.$parent.fetchCities(departmentId)
    })
    this.$root.$on('fetch-cities-company', async (departmentId) => {
      this.$parent.fetchCitiesCompany()
    })
    this.$root.$on('send-notification', (notificationData) => {
      this.$parent.send_notification(notificationData.id, this.elementData.id)
    })
    this.$root.$on('try_contact_failed', (try_contact_failed) => {
      this.try_contact_failed = try_contact_failed
    })
    this.onDepartmentChange()
    this.internalCaseStatusList = this.caseStatusList
    this.check_try_contact_failed()
  },
  methods: {
    router() {
      return router
    },
    formatDate(element) {
      console.log('dating', element)
      return element
        ? moment(element, 'DD/MM/YYYYTHH:mm:ss').format('DD/MM/YYYY HH:mm')
        : ''
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split(/[\/,\\]+/)
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    async addFile() {
      this.$root.$emit('add-file', this.file)
    },
    change_known_source(new_element) {
      const element = document.getElementById('source_fields')

      if (
        new_element &&
        this.booleanNAList.find((element) => new_element === element.id).key ===
          'YES'
      ) {
        element.classList.remove('hidden-div')
      } else {
        element.classList.add('hidden-div')
      }
    },
    async onDepartmentChange() {
      if (this.elementData.department_id) {
        this.cityCaseList = await this.$parent.fetchCities(
          this.elementData.department_id
        )
      }
      this.elementData.city_id = this.cityCaseList
        ? this.cityCaseList.find(
            (element) => element.id == this.elementData.city_id
          )?.id
        : null
    },

    searchPatient() {
      this.$parent.fetchPatient(
        this.elementData.patient.document_type_id,
        this.elementData.patient.identity
      )
      this.patientIsDisabled = true
      this.patientSearched = true
    },
    async searchCompany() {
      this.$parent.fetchCompany(this.elementData.company.nit)
    },
    showPatientModal() {
      this.$root.$emit('bv::show::modal', 'modal-patient')
    },
    showCompanyModal() {
      this.$root.$emit('bv::show::modal', 'modal-company')
    },
    changePatient() {
      this.patientIsDisabled = false
      this.patientSearched = false
      this.elementData.patient.identity = null
      this.elementData.patient.first_name = null
      this.elementData.patient.second_name = null
      this.elementData.patient.first_surname = null
      this.elementData.patient.second_surname = null
    },
    resolveUserStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveUserStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    validate(elementData) {
      if (this.try_contact_failed) {
        return true
      }

      const errors = []
      if (
        moment(elementData.accident_date).isAfter(
          moment(elementData.report_date)
        )
      ) {
        errors.push(
          this.$t(`${this.resourcesElementName}.errors.date_comparission`)
        )
      }
      if (moment(elementData.accident_date).isAfter(moment())) {
        errors.push(
          this.$t(
            `${this.resourcesElementName}.errors.date_comparission_accident`
          )
        )
      }
      if (moment(elementData.report_date).isAfter(moment())) {
        errors.push(
          this.$t(
            `${this.resourcesElementName}.errors.date_comparission_report`
          )
        )
      }
      if (!elementData.company.nit) {
        errors.push(
          this.$t(`${this.resourcesElementName}.errors.company_required`)
        )
      }
      if (!elementData.patient.birthdate) {
        errors.push(
          this.$t(`${this.resourcesElementName}.errors.patient_required`)
        )
      }
      if (errors.length) {
        for (const error of errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(
                `${this.resourcesElementName}.errors.validations_title`
              ),
              icon: 'SaveIcon',
              variant: 'danger',
              text: error,
            },
          })
        }
        return false
      }
      return true
    },
    process_dates() {
      for (const step of this.elementData.process.steps) {
        if (step.case_date && step.case_date.start_date) {
          if (moment.isMoment(step.case_date.start_date)) {
            step.case_date.start_date =
              step.case_date.start_date.format('DD/MM/YYYY HH:mm')
            step.case_date.end_date =
              step.case_date.end_date.format('DD/MM/YYYY HH:mm')
          }
        }
      }
    },
    save() {
      this.$refs.elementForm.validate().then(async (success) => {
        if (!this.submitting) {
          try {
            this.submitting = true
            await new Promise((r) => setTimeout(r, 4000))
            const _self = this
            if (success) {
              if (typeof this.elementData.priority_case === 'undefined') {
                this.elementData.priority_case = false; 
              }
              if (this.validate(this.elementData)) {
                if ('administration_route' in this.elementData) {
                  delete this.elementData.administration_route
                }
                if ('service_type' in this.elementData) {
                  delete this.elementData.service_type
                }
                if ('units_of_measure' in this.elementData) {
                  delete this.elementData.units_of_measure
                }
                if ('reference_unit' in this.elementData) {
                  delete this.elementData.reference_unit
                }
                if ('dosage_form' in this.elementData) {
                  delete this.elementData.dosage_form
                }
                if ('regulated' in this.elementData) {
                  delete this.elementData.regulated
                }
                if ('minimum_delivery_unit' in this.elementData) {
                  delete this.elementData.minimum_delivery_unit
                }
                if (!_self.elementData.cum_quantity)
                  _self.elementData.cum_quantity = null
                this.process_dates()
                await this.$parent.save(this.elementData)
              }
            }
          } finally {
            this.submitting = false
          }
        }
      })
    },
    check_try_contact_failed() {
      this.try_contact_failed = this.elementData.process.steps.some(
        (step) => step.step_status?.key === 'FAILED'
      )
    },
  },
}
</script>

<style>
.hidden-div {
  display: none;
}
</style>
