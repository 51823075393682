<template>
  <b-card>
    <b-card-body>
      <validation-observer ref="elementForm" #default="{ invalid }">
        <b-form @submit.prevent="save">
          <template v-if="has_actions()">
            <!-- Per Page -->
            <div v-if="has_action('add_date')">
              <table>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BookOpenIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + ".object.date.doctor_name")
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{ get_doctor(elementData.case_date.doctor_id) }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BookOpenIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + ".object.date.start_date")
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{ formatDate(elementData.case_date, "start_date") }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BookOpenIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + ".object.date.end_date")
                    }}</span>
                  </th>
                  <td class="pb-50">
                    {{ formatDate(elementData.case_date, "end_date") }}
                  </td>
                </tr>
              </table>
              <b-button variant="primary" @click="editDate">
                <span class="text-nowrap">{{
                  $t(resourcesElementName + ".object.edit_date")
                }}</span>
              </b-button>
            </div>
            <div v-if="has_action('register_try_contact')">
              <b-row>
                <b-col cols="6" md="6">
                  <b-form-group label-for="contact">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + ".object.contact")
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t(resourcesElementName + '.object.contact')"
                      rules="required"
                    >
                      <v-select
                        v-model="elementData.contact_id"
                        :options="contactList"
                        label="data"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        @input="change_contact"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6" md="6">
                  <b-form-group label-for="contact_status">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">{{
                      $t(resourcesElementName + ".object.contact_status")
                    }}</span>
                    <validation-provider
                      #default="{ errors }"
                      :name="
                        $t(resourcesElementName + '.object.contact_status')
                      "
                      rules="required"
                    >
                      <v-select
                        v-model="elementData.contact_status_id"
                        :options="contactStatusList"
                        label="value"
                        value="id"
                        :reduce="(option) => option.id"
                        :placeholder="$t('list.defaultOption')"
                        @input="change_status_contact"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Right Col: Table -->
              </b-row>
            </div>
          </template>

          <div v-if="has_action('notification')" class="d-flex">
            <notifications-edit-tab
              v-if="elementData"
              :new-element="newElement"
              :element-data="elementData"
              :notification-config-list="notificationConfigList"
              :notification-medium-list="notificationMediumList"
              :contact-list="contactList"
              class="mt-2 pt-75"
            />
          </div>

          <b-row class="d-flex justify-content-end">
            <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="hideModal()">
              {{ $t("actions.back") }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t("actions.save") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>

      <div>
        <b-modal
          id="modal-date"
          size="xl"
          no-close-on-esc
          no-close-on-backdrop
          no-modal-header-close
          hide-footer
          :title="$t(resourcesElementName + '.object.edit_calendar_title')"
        >
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <b-button
              v-b-modal.close-confirmation
              size="sm"
              variant="outline-danger"
            >
              Cancelar
            </b-button>
            <h5>
              {{ $t(resourcesElementName + ".object.edit_calendar_title") }}
            </h5>
          </template>
          <template>
            <calendar-edit-tab
              :element-data="elementData.case_date"
              class="mt-2 pt-75"
            />
          </template>
        </b-modal>
        <b-modal
          id="close-confirmation"
          size="sm"
          title="Confirmación"
          hide-footer
        >
          <template>
            <p class="my-1">Si cierra el diálogo perderá los cambios</p>
            <p>¿Desea continuar?</p>
            <b-button
              size="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="danger"
              @click="reset_changes()"
            >
              Aceptar
            </b-button>
            <b-button
              size="mb-1 mb-sm-0 mr-0 mr-sm-1"
              variant="primary"
              @click="close_confirmation()"
            >
              Cancelar
            </b-button>
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BModal,
} from "bootstrap-vue";
import { required } from "@validations";
import CalendarEditTab from "@/views/schedules/calendar/index.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import vSelect from "vue-select";
import NotificationsEditTab from "./EditNotifications.vue";
import "@core/scss/vue/libs/vue-select.scss";

export default {
  components: {
    CalendarEditTab,
    NotificationsEditTab,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BModal,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    stepTypeList: {
      type: Array,
      required: true,
    },
    stepStatusList: {
      type: Array,
      required: true,
    },
    notificationConfigList: {
      type: Array,
      required: true,
    },
    notificationMediumList: {
      type: Array,
      required: true,
    },
    contactStatusList: {
      type: Array,
      required: true,
    },
    contactList: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    caseStatusList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: "step",
      temporalElement: {},
    };
  },
  mounted() {
    this.change_type();
  },
  methods: {
    formatDate(element, field) {
      let ret = moment(element[field], "DD/MM/YYYY").format("DD/MM/YYYY HH:mm");
      if (ret == "Invalid date" || ret == "Fecha inválida") {
        ret = moment(element[field]).format("DD/MM/YYYY HH:mm");
      }
      return ret == "Invalid date" || ret == "Fecha inválida" ? "" : ret;
    },
    change_contact() {
      if (this.elementData.contact_id) {
        const index = this.contactList.findIndex(
          (element) => element.id == this.elementData.contact_id
        );
        if (index >= 0) this.elementData.contact = this.contactList[index];
      }
    },
    change_status_contact() {
      if (this.elementData.contact_status_id) {
        const index = this.contactStatusList.findIndex(
          (element) => element.id == this.elementData.contact_status_id
        );
        if (index >= 0)
          this.elementData.contact_status = this.contactStatusList[index];
      }
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-step");
    },
    close_confirmation() {
      this.$root.$emit("bv::hide::modal", "close-confirmation");
    },
    reset_changes() {
      Object.assign(this.elementData.case_date, this.temporalElement);
      this.$root.$emit("bv::hide::modal", "modal-date");
      this.$root.$emit("bv::hide::modal", "close-confirmation");
    },
    editDate() {
      Object.assign(this.temporalElement, this.elementData.case_date);
      this.$root.$emit("bv::show::modal", "modal-date");
    },
    has_actions() {
      return this.actions.length > 1 || this.actions[0] !== "notification";
    },
    has_action(action) {
      return this.actions.findIndex((element) => element == action) > -1;
    },
    change_type() {
      if (this.elementData.step_type_id) {
        this.$root.$emit(
          "fetch-notification-config",
          this.elementData.step_type_id
        );
        const index = this.stepTypeList.findIndex(
          (element) => element.id == this.elementData.step_type_id
        );
        if (index >= 0) this.elementData.step_type = this.stepTypeList[index];
      }
    },
    change_step_status() {
      if (this.elementData.step_status_id) {
        const index = this.stepStatusList.findIndex(
          (element) => element.id == this.elementData.step_status_id
        );
        if (index >= 0)
          this.elementData.step_status = this.stepStatusList[index];
      }
    },
    get_doctor(doctor_id) {
      const index = this.userList.findIndex(
        (element) => element.id == doctor_id
      );
      if (index >= 1) {
        const obj = this.userList[index];
        return obj.name;
      }
      return "";
    },
    save() {
      const _self = this;
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          let error = "";
          if (_self.elementData.step_type.key === "FIRST_DATE") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "FIRST_DATE_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "FIRST_FOLLOW") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "FIRST_FOLLOW_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "SECOND_FOLLOW") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "SECOND_FOLLOW_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "THIRD_FOLLOW") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "THIRD_FOLLOW_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "FOURTH_FOLLOW") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "FOURTH_FOLLOW_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "ADDITIONAL") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "ADDITIONAL_SCHEDULED"
              )
            );
          }
          if (_self.elementData.step_type.key === "EVOLUTION") {
            success = success && _self.elementData.case_date.doctor_id;
            success = success && _self.elementData.notifications.length > 0;
            error = this.$t(`${this.resourcesElementName}.errors.schedule`);
            _self.$root.$emit(
              "update-case-status",
              _self.caseStatusList.find(
                (element) => element.key == "EVOLUTION_SCHEDULED"
              )
            );
          }
          if (success) {
            _self.$root.$emit("save-step", _self.elementData);
            _self.$root.$emit("bv::hide::modal", "modal-step");
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t(
                  `${this.resourcesElementName}.errors.validations_title`
                ),
                icon: "SaveIcon",
                variant: "danger",
                text: error,
              },
            });
          }
        }
      });
    },
  },
};
</script>

<style>
.hidden-div {
  display: none;
}
</style>
