<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.main_information') }}</span>
      </div>
    </b-card-header>
    <b-card-body>

      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="save"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="config"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.config') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.config')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.noti_config_id"
                    :options="notificationConfigList"
                    label="email_subject"
                    value="id"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="noti_medium"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.noti_medium') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.noti_medium')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.noti_medium_id"
                    :options="notificationMediumList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label-for="contact"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.contact') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.contact')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.contact_id"
                    :options="contactListFilter"
                    label="data"
                    value="id"
                    :reduce="option => option.id"
                    @input="change_contact"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
          </b-row>
          <b-row class="d-flex justify-content-end">
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="hideModal()"
            >
              {{ $t('actions.back') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="invalid"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BCard, BButton, BRow, BCol, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    notificationConfigList: {
      type: Array,
      required: true,
    },
    notificationMediumList: {
      type: Array,
      required: true,
    },
    contactList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      resourcesElementName: 'notification',
    }
  },
  computed: {
    contactListFilter() {
      const medium = this.notificationMediumList.find(element => element.id == this.elementData.noti_medium_id)
      let contactListFilter = this.contactList
      switch (medium.key) {
        case 'SMS':
          contactListFilter = contactListFilter.filter(element => element.type.key == 'PHONE' || element.type.key == 'CELL_PHONE')
          break
        case 'WhatsApp':
          contactListFilter = contactListFilter.filter(element => element.type.key == 'PHONE' || element.type.key == 'CELL_PHONE')
          break
        case 'EMAIL':
          contactListFilter = contactListFilter.filter(element => element.type.key == 'EMAIL')
          break
      }
      return contactListFilter
    },
  },
  mounted() {
    this.$root.$emit('fetch-cities', this.elementData.department_id)
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-notification')
    },
    change_contact() {
      if (this.elementData.contact_id) {
        const index = this.contactList.findIndex(element => element.id == this.elementData.contact_id)
        if (index >= 0) this.elementData.contact = this.contactList[index]
      }
    },
    save() {
      const _self = this
      this.$refs.elementForm.validate().then(success => {
        if (success) {
          _self.$root.$emit('save-notification', _self.elementData)
          _self.$root.$emit('bv::hide::modal', 'modal-notification')
        }
      })
    },
  },
}
</script>

<style>

.hidden-div{
    display: none;
  }
</style>
