// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'

import moment from 'moment'
import esLocale from '@fullcalendar/core/locales/es'

export default function userCalendar() {
  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: '',
    start_date: '',
    end_date: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const setEvent = elementData => {
    blankEvent.start_date = moment(elementData.start_date).format('DD/MM/YYYY HH:mm')
    blankEvent.end_date = moment(elementData.end_date).format('DD/MM/YYYY HH:mm')
    blankEvent.date = moment(elementData.start_date).format('DD/MM/YYYY HH:mm')
    blankEvent.title = get_type(blankEvent) + get_doctor(blankEvent) + elementData.start_date
    blankEvent.description = elementData.description
    blankEvent.url = elementData.url
    blankEvent.doctor_id = elementData.doctor_id
    blankEvent.type_id = elementData.type_id
    event.value = JSON.parse(JSON.stringify(blankEvent))

    if (event.value.doctor_id) {
      isEventHandlerSidebarActive.value = true
      document.getElementById('calendar_sidebar').classList.add('show')
    } else {
      isEventHandlerSidebarActive.value = false
      document.getElementById('calendar_sidebar').classList.remove('show')
    }
  }
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start_date, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  // const grabEventDataFromEventApi = eventApi => {
  //   const {
  //     id,
  //     title,
  //     start_date,
  //     end_date,
  //     // eslint-disable-next-line object-curly-newline
  //     extendedProps: { calendar, guests, location, description },
  //     allDay,
  //   } = eventApi

  //   return {
  //     id,
  //     title,
  //     start_date,
  //     end_date,
  //     extendedProps: {
  //       calendar,
  //       guests,
  //       location,
  //       description,
  //     },
  //     allDay,
  //   }
  // }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'url']
      const extendedPropsToUpdate = ['calendar', 'guests', 'location', 'description']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
    })
  }

  const isBetween = (date, compare_from, compare_to) => date.isBetween(compare_from, compare_to) || date.isSame(compare_from) || date.isSame(compare_to)

  const hasCollision = (time, elementData) => {
    const mom_time = moment(time)
    const final_time = moment(elementData.end_date, 'DD/MM/YYYY HH:mm')
    const mom_time_start = mom_time.format('DD/MM/YYYY')
    const new_availabilities = calendarOptions.value.events.filter(element => isSame(mom_time_start, element.date))
    if (new_availabilities) {
      for (const eve of new_availabilities) {
        const ele_time = moment(eve.date)
        const final_ele_time = moment(eve.end_date)
        if (elementData.id != eve.id && (isBetween(ele_time, mom_time, final_time) || isBetween(final_ele_time, mom_time, final_time))) {
          // ele_time.hours() >= mom_time.hours() && ele_time.hours() <= final_time.hours()) {
          // if (ele_time.hours() > mom_time.hours() || parseInt(eve.final_unavailability.HH) < mom_time.hours()) {
          console.log('big bang')
          return true
          // }
        }
      }
    }
    return false
  }

  const hasAvailabilityInHour = (time, ava, elementData) => {
    const mom_time = moment(time)
    if (parseInt(ava.initial_availability.HH) <= mom_time.hours() && parseInt(ava.final_availability.HH) >= mom_time.hours()) {
      if (parseInt(ava.initial_unavailability.HH) >= mom_time.hours() || parseInt(ava.final_unavailability.HH) <= mom_time.hours()) {
        if (!hasCollision(time, elementData)) {
          return true
        }
      }
    }
    return false
  }

  const isSame = (mom_time, calendar_date) => {
    const date = moment(calendar_date).format('DD/MM/YYYY')
    return mom_time === date
  }

  const hasAvailability = (time, elementData) => {
    if (!time) { return false }
    let mom_time = moment(time).startOf('day')
    const now = moment().startOf('day')
    if (mom_time.isSameOrAfter(now)) {
      mom_time = mom_time.format('DD/MM/YYYY')
      if (availabilities.value) {
        const new_availabilities = availabilities.value.filter(element => isSame(mom_time, element.calendar_date))
        if (new_availabilities) {
          for (const ava of new_availabilities) {
            if (!elementData || hasAvailabilityInHour(time, ava, elementData)) {
              return true
            }
          }
        }
      }
    }
    return false
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  const get_type = event => {
    if (event && event.type_id) {
      const element = dateTypeList.value.find(l => l.id == event.type_id)
      return element ? element.value : ''
    }
    return ''
  }

  const get_doctor = event => {
    if (event && event.doctor_id) {
      const element = doctorList.value.find(l => l.id == event.doctor_id)
      return element ? element.full_name : ''
    }
    return ''
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)
  const selectedDateType = computed(() => store.state.calendar.selectedDateType)
  const dateTypeList = computed(() => store.state.calendar.dateTypeList)
  const doctorList = computed(() => store.state.calendar.calendarOptions)
  const availabilities = computed(() => store.state.calendar.selectedAvailabilities)
  const doctor_id = computed(() => store.state.calendar.doctor_id)

  const fetch_key_value = async key =>
    // Fetch Events from API endpoint
    await store
      .dispatch('calendar/fetch_key_value', key).then(response => response.data.items).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events 3',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return []
      })

  const fetchEvents = async (start, end) => {
    // var ret = event && event.value ? [event.value] : []
    // calendarOptions.value.events = ret
    // return ret
    // Fetch Events from API endpoint
    if (doctor_id) {
      const id = doctor_id.value
      return store
        .dispatch('calendar/fetchCalendar', { doctor_id: id, start, end }).then(response => {
          const ret = []
          if (response.data) {
            for (const ev of response.data) {
              ret.push({
                id: ev.id,
                date: ev.start_date,
                end_date: ev.end_date,
                title: ev.type.value + get_doctor(ev) + ev.start_date,
              })
            }
          }
          return ret
        }).catch(err => {
          console.log(err)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching calendar events 4',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    }
    return []
  }
  // const fetchAvailabilities = async (calendars, type) => {
  //   // Fetch Events from API endpoint
  //   return await store
  //     .dispatch('calendar/fetchEvents', calendars, type).then(response => {
  //       return response.data.items
  //     }).catch(() => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Error fetching calendar events',
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //       return []
  //     })
  //   return []
  // }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: 'timeGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev,next',
      center: 'title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    config: {
      weekends: false,
    },
    // businessHours: availabilies,
    locale: esLocale,
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    disableDragging: true,

    eventStartEditable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: false,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = 'primary'

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    eventClick({ event: clickedEvent }) {
      console.log(clickedEvent)
      return
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      if (!event.value.doctor_id) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Agendamiento',
            text: 'Por favor seleccione un médico para agendar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }
      if (hasAvailability(info.date)) {
        const date = moment(info.date).format('DD/MM/YYYY h:mm A')
        const end_date = moment(info.date).add(30, 'minutes').format('DD/MM/YYYY h:mm A')
        event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { date, start_date: date, end_date })))
        // eslint-disable-next-line no-use-before-define
        isEventHandlerSidebarActive.value = true
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Agendamiento',
            text: 'El médico no tiene disponibilidad en la agenda para la fecha seleccionada',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      // updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      // updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    updateEvent,
    removeEvent,
    fetchEvents,
    fetch_key_value,
    setEvent,
    hasAvailability,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
