<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        id="calendar_sidebar"
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{'show': isCalendarOverlaySidebarActive}"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :event="event"
          :is-new-event="isNewEvent()"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              id="avenir_calendar"
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        :calendar-options="calendarOptions"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import router from '@/router'
import { onMounted, onUnmounted } from '@vue/composition-api'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  props: {
    elementData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      availabilities: [],
    }
  },
  async mounted() {
    const _self = this
    store.commit('calendar/SET_CALENDAR_OPTIONS', await this.fetchDoctors())

    document.getElementsByClassName('fc-dayGridMonth-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-timeGridWeek-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-timeGridDay-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-prev-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    document.getElementsByClassName('fc-next-button')[0].addEventListener('click', async event => {
      await new Promise(r => setTimeout(r, 500))
      _self.processAvailabilities()
      await this.updateEvents()
    })
    this.setEvent(_self.elementData)
    if (_self.elementData && _self.elementData.doctor_id) {
      this.changeDoctor(_self.elementData.doctor_id)
    }
  },
  methods: {
    isNewEvent() {
      return this.elementData && this.elementData.case_id && this.elementData.case_id > -1
    },
    processAvailabilities() {
      const week_tables = document.querySelectorAll('table[role="presentation"]')
      if (week_tables) {
        for (const week_table of week_tables) {
          for (const td of week_table.rows) {
            for (const cell of td.cells) {
              cell.classList.add('fc-my-non-business')
              if (this.hasAvailability(cell.getAttribute('data-date'))) {
                cell.classList.remove('fc-my-non-business')
              }
            }
          }
        }
      }
      const month_table = document.getElementsByClassName('fc-scrollgrid-sync-table')
      if (month_table) {
        for (const td of month_table[0].rows) {
          for (const cell of td.cells) {
            cell.classList.add('fc-my-non-business')
            if (this.hasAvailability(cell.getAttribute('data-date'))) {
              cell.classList.remove('fc-my-non-business')
              if (moment(cell.getAttribute('data-date')).startOf('day').isSame(moment().startOf('day'))) {
                cell.style.cssText = ''
              }
            } else if (moment(cell.getAttribute('data-date')).startOf('day').isSame(moment().startOf('day'))) {
              cell.style.cssText = 'background: #F2DCDB !important'
            }
          }
        }
      }
    },
    async changeDoctor(newDoctor) {
      const _self = this
      this.availabilities = await store
        .dispatch('calendar/fetchDoctorAvailability', { newDoctor })
        .then(response => response.data.availabilities).catch(() => {
          _self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching calendar events 1',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        })
      store.commit('calendar/SET_SELECTED_AVAILABILITIES', this.availabilities)
      this.processAvailabilities()
      store.commit('calendar/SET_SELECTED_DOCTOR', newDoctor)

      console.log('se llama fetchEvents desde changeDoctor')
      await this.updateEvents()
    },
    async updateEvents() {
      const calendarApi = this.$refs.refCalendar.getApi()
      const currentData = calendarApi.getCurrentData()
      const { start, end } = currentData.dateProfile.activeRange
      this.calendarOptions.events = await this.fetchEvents(start, end)
    },
    async addEvent(eventData) {
      this.elementData.description = eventData.description
      this.elementData.end_date = moment(eventData.end_date, 'DD/MM/YYYY h:mm A')
      store.commit('calendar/SET_SELECTED_DOCTOR', eventData.doctor_id)

      console.log('se llama fetchEvents desde addEvent')
      await this.updateEvents()

      this.elementData.doctor_id = eventData.doctor_id
      this.elementData.start_date = moment(eventData.start_date, 'DD/MM/YYYY h:mm A')
      this.elementData.type_id = eventData.type_id
      if (this.hasAvailability(moment(this.elementData.start_date, 'DD/MM/YYYY h:mm A'), this.elementData)) {
        this.$root.$emit('update-case-date', this.elementData)
        this.$root.$emit('bv::hide::modal', 'modal-date')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Agendamiento',
            text: 'El médico no tiene disponibilidad en la agenda para la fecha seleccionada',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async fetchDoctors() {
      // Fetch Events from API endpoint
      const _self = this
      return await store
        .dispatch('calendar/fetchDoctors', {
        }).then(response => {
          const element = response.data.items.map(element => ({
            id: element.id,
            full_name: `${element.firt_name} ${
              element.middle_name ? `${element.middle_name} ` : ''
            }${element.surname_1} ${
              element.surname_2 ? `${element.surname_2} ` : ''}`,
          }))
          return element
        }).catch(() => {
          _self.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching calendar events 2',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      fetchEvents,
      fetch_key_value,
      calendarOptions,
      setEvent,
      hasAvailability,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    onMounted(async () => {
      store.commit('calendar/SET_DATE_TYPE_LIST', await fetch_key_value('date_type'))
    })

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      updateEvent,
      removeEvent,
      fetchEvents,
      fetch_key_value,
      calendarOptions,
      setEvent,
      hasAvailability,
      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.fc-my-non-business {
  background: var(--fc-non-business-color, rgba(242, 220, 219, 1)) !important
}
.fc .fc-my-non-business {
  background: var(--fc-non-business-color, rgba(15, 110, 124, 0.2)) !important
}
.fc .fc-day-today {
  background: #A2D9CE !important
}
</style>
