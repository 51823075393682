<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary" />
        <span class="d-none d-sm-inline">{{
          $t(resourcesElementName + '.section.process_data')
        }}</span>
      </div>
    </b-card-header>
    <b-card-body v-if="elementData.process && resourcesElementName">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- ? REVISAR -->
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-button
              v-if="is_step_enabled('try_contact') && !isComplete"
              class="mr-sm-1"
              variant="primary"
              @click="addTryContact">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.try_contact')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_consent') && isComplete"
              class="mr-sm-1"
              variant="primary"
              @click="addConsent">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_consent')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_first_date')"
              class="mr-sm-1"
              variant="primary"
              @click="addFirstDate">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_first_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_first_follow')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('FIRST_FOLLOW')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_first_follow_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_second_follow')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('SECOND_FOLLOW')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_second_follow_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_third_follow')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('THIRD_FOLLOW')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_third_follow_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_fourth_follow')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('FOURTH_FOLLOW')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_fourth_follow_date')
              }}</span>
            </b-button>
            <!-- ? BUTTON ADDICIONAL -->
            <b-button
              v-if="is_step_enabled('add_additional')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('ADDITIONAL')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_additional_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('add_evolution')"
              class="mr-sm-1"
              variant="primary"
              @click="addFollowDate('EVOLUTION')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.add_evolution_date')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('ARL_CLOSURE')"
              class="mr-sm-1"
              variant="primary"
              @click="changeCaseStatus('ARL_CLOSURE')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.arl_closure')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('ADMIN_CLOSURE')"
              class="mr-sm-1"
              variant="primary"
              @click="changeCaseStatus('ADMIN_CLOSURE')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.admin_closure')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('LOST')"
              class="mr-sm-1"
              variant="primary"
              @click="changeCaseStatus('LOST')">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.lost_case')
              }}</span>
            </b-button>
            <b-button
              v-if="is_step_enabled('REOPEN_CASE')"
              class="mr-sm-1"
              variant="primary"
              @click="reopenCase()">
              <span class="text-nowrap">{{
                $t(resourcesElementName + '.object.reopen_case')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="elementData.process.steps"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(resourcesElementName + '.search.not_found')">
        <!-- column: name -->
        <template #cell(data)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ data.item.data }}
          </b-badge>
        </template>
        <template #cell(step_type)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ getTypeValue(data.item.step_type) }}
          </b-badge>
        </template>
        <template #cell(step_status)="data">
          <b-badge
            pill
            class="text-capitalize">
            {{ getTypeValue(data.item.step_status) }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <h2 class="inline">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize mr-0 mt-50">
              {{ resolveStatusMessage(data.item.status) }}
            </b-badge>
          </h2>
        </template>
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="get_edit_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="editStep(data.item)">
              <feather-icon icon="EditIcon" />
            </b-button>

            <b-button
              v-if="data.item.status"
              :id="get_deactivate_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="deactivateStep(data.item.id)">
              <feather-icon icon="DeleteIcon" />
            </b-button>

            <b-button
              v-else
              :id="get_reactivate_button_id(data.item.id)"
              style="margin: 1px"
              variant="primary"
              @click="reactivateStep(data.item.id)">
              <feather-icon icon="PlusIcon" />
            </b-button>

            <!-- ? EDIT -->
            <b-tooltip
              :target="get_edit_button_id(data.item.id)"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="topleft">
            </b-tooltip>
            <!-- ? DEACTIVATE -->
            <b-tooltip
              v-if="data.item.status"
              :target="get_deactivate_button_id(data.item.id)"
              :title="$t('actions.deactivate')"
              triggers="hover"
              :noninteractive="true"
              placement="topleft">
            </b-tooltip>
            <!-- ? REACTIVATE -->
            <b-tooltip
              v-else
              :target="get_reactivate_button_id(data.item.id)"
              :title="$t('actions.reactivate')"
              triggers="hover"
              :noninteractive="true"
              placement="topleft">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
      <div>
        <b-modal
          id="modal-step"
          size="lg"
          hide-footer
          :title="getStepTitle()">
          <template>
            <step-edit-tab
              :new-element="newElement"
              :element-data="stepData"
              :actions="actions"
              :step-type-list="stepTypeList"
              :step-status-list="stepStatusList"
              :notification-config-list="notificationConfigList"
              :notification-medium-list="notificationMediumList"
              :contact-status-list="contactStatusList"
              :user-list="userList"
              :case-status-list="caseStatusList"
              :contact-list="getContactList()"
              :contact-type-list="contactTypeList"
              class="mt-2 pt-75" />
          </template>
        </b-modal>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StepEditTab from './EditStep.vue'

export default {
  components: {
    BTooltip,
    StepEditTab,
    BDropdown,
    BDropdownItem,
    BModal,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    stepTypeList: {
      type: Array,
      required: true,
    },
    stepStatusList: {
      type: Array,
      required: true,
    },
    notificationConfigList: {
      type: Array,
      required: true,
    },
    dateTypeList: {
      type: Array,
      required: true,
    },
    userList: {
      type: Array,
      required: true,
    },
    contactStatusList: {
      type: Array,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
    notificationMediumList: {
      type: Array,
      required: true,
    },
    caseStatusList: {
      type: Array,
      required: true,
    },
    consultTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      resourcesElementName: '',
      required,
      stepData: {},
      case_dateAdditional: {},
      isComplete: false,
      newContact: true,
      actions: [],
      orders: [
        'Primera',
        'Segunda',
        'Tercera',
        'Cuarta',
        'Quinta',
        'Sexta',
        'Séptima',
        'Octava',
        'Novena',
        'Décima',
      ],
    }
  },
  beforeDestroy() {
    this.$root.$off('save-step')
    this.$root.$off('update-case-status')
    this.$root.$off('update-case-date')
    this.stopAdditionalSort()
  },
  mounted() {
    const _self = this
    this.$root.$on('save-step', (stepData) => {
      console.log('save step')
      const index = this.elementData.process.steps.findIndex(
        (element) => element.id == stepData.id
      )
      if (index < 0) _self.elementData.process.steps.push(stepData)
      const hayFallo = _self.elementData.process.steps.some(
        (step) => step.contact_status?.key === 'FAILED'
      )
      _self.$root.$emit('try_contact_failed', hayFallo)
    })
    this.$root.$on('update-case-status', (case_status) => {
      _self.elementData.case_status = case_status
      _self.elementData.case_status_id = _self.elementData.case_status.id
    })
    this.$root.$on('update-case-date', (eventData) => {
      if (!_self.stepData.case_date) {
        _self.stepData.case_date = {}
      }
      _self.stepData.case_date.description = eventData.description
      _self.stepData.case_date.end_date = eventData.end_date
      _self.stepData.case_date.doctor_id = eventData.doctor_id
      _self.stepData.case_date.start_date = eventData.start_date
      _self.stepData.case_date.type_id = eventData.type_id
      _self.$forceUpdate()
    })
    this.resourcesElementName = 'process'
    this.tableColumns = [
      {
        key: 'step_type',
        label: this.$t(`${this.resourcesElementName}.object.step_type`),
        sortable: true,
      },
      {
        key: 'step_status',
        label: this.$t(`${this.resourcesElementName}.object.step_status`),
        sortable: true,
      },
      {
        key: 'status',
        label: this.$t(`${this.resourcesElementName}.object.status`),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.resourcesElementName}.object.actions`),
      },
    ]
    this.AdditionalSort()
  },
  methods: {
    AdditionalSort() {
      this.intervalId = setInterval(() => {
        let additionalCount = 0
        this.elementData.process.steps
          .sort((a, b) => {
            if (a.id < 0 && b.id < 0) return 0
            if (a.id < 0) return 1
            if (b.id < 0) return -1
            return a.id - b.id
          }).map((step) => {
            if (step.step_type.key === 'ADDITIONAL') {
              step.step_type.value = `${this.orders[additionalCount]} consulta adicional`
              additionalCount++
            }
            return step
          })
      }, 50)
    },
    stopAdditionalSort() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    getStepTitle() {
      return this.stepData.step_type ? this.stepData.step_type.value : ''
    },
    getContactList() {
      const contactList = this.elementData.patient.contacts.filter(
        (element) => element.status == 1
      )
      for (const contact of contactList) {
        if (!contact.type) {
          contact.type = this.contactTypeList.find(
            (element) => element.id == contact.type_id
          )
        }
      }
      return contactList
    },
    getTypeValue(element) {
      return element ? element.value : ''
    },
    get_edit_button_id(key) {
      return `edit_button${key}`
    },
    get_deactivate_button_id(key) {
      return `deactivate_button${key}`
    },
    get_reactivate_button_id(key) {
      return `reactivate_button${key}`
    },
    editStep(item) {
      item.case_date
        ? this.$set(this.case_dateAdditional, item.id, item.case_date)
        : (item.case_date = this.case_dateAdditional[item.id])

      this.stepData = item
      this.setActionsByType()
      this.$root.$emit('bv::show::modal', 'modal-step')
    },
    setActionsByType() {
      switch (this.stepData.step_type.key) {
        case 'TRY_CONTACT':
          this.actions = ['register_try_contact']
          break
        case 'CONSENT':
          this.actions = ['notification']
          break
        default:
          this.actions = ['notification', 'add_date']
      }
    },
    addTryContact() {
      const type = this.stepTypeList.find(
        (element) => element.key == 'TRY_CONTACT'
      )
      this.stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
      }
      this.setActionsByType()
      this.$root.$emit('bv::show::modal', 'modal-step')
    },
    addConsent() {
      const type = this.stepTypeList.find((element) => element.key == 'CONSENT')
      this.stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
      }
      this.setActionsByType()
      this.$root.$emit('bv::show::modal', 'modal-step')
    },
    addFirstDate() {
      if (!this.elementData.initial_valuation_auth_code) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'SaveIcon',
            variant: 'danger',
            text: 'La consulta no tiene código ARL, por favor verifique e intente nuevamente.',
          },
        })
        return
      }

      const type = this.stepTypeList.find(
        (element) => element.key == 'FIRST_DATE'
      )
      const consult_type = this.consultTypeList.find(
        (element) => element.key == 'INITIAL_VALORATION'
      )
      this.stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
        case_date: {
          consult_type_id: consult_type.id,
          case_id: this.elementData.id,
          end_date: '',
          description: '',
          doctor_id: null,
          start_date: '',
          type_id: this.getDateTypeKey('FIRST').id,
        },
      }
      this.setActionsByType()
      this.$root.$emit('bv::show::modal', 'modal-step')
    },
    changeCaseStatus(statusKey) {
      console.log('Contenido de stepTypeList:', this.stepTypeList);
      const type = this.stepTypeList.find(
        (element) => element.key == `CHANGE_STATUS_${statusKey}`
      )
      if (!type) {
        console.error('No se encontró el tipo de paso para el estado:', statusKey);
        return;
      }
      const step_status = this.stepStatusList.find(
        (element) => element.key === 'PENDING'
      )
      if (!step_status) {
        console.error('No se encontró el estado pendiente en la lista stepStatusList');
        return;
      }
      const stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
        step_status,
      }

      const case_status = this.caseStatusList.find(
        (element) => element.key == statusKey
      )

      if (!case_status) {
        console.error('No se encontró el estado del caso en caseStatusList:', statusKey);
        return;
      }

      this.elementData.case_status = case_status
      this.elementData.case_status_id = case_status.id

      this.elementData.process.steps.push(stepData)
    },
    reopenCase() {
      const type = this.stepTypeList.find(
        (element) => element.key == 'REOPEN_CASE'
      )
      const step_status = this.stepStatusList.find(
        (element) => element.key === 'PENDING'
      )
      const stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
        step_status,
      }

      const case_status = this.caseStatusList.find(
        (element) => element.key == 'REOPEN_CASE'
      )

      this.elementData.case_status = case_status
      this.elementData.case_status_id = case_status.id

      this.elementData.process.steps.push(stepData)
    },
    addFollowDate(key) {
      if (!this.is_step_in_medical_orders(key) && key !== 'EVOLUTION') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'SaveIcon',
            variant: 'danger',
            text: 'La consulta no tiene código ARL, por favor verifique e intente nuevamente.',
          },
        })
        return
      }
      const type = this.stepTypeList.find((element) => element.key == key)
      const consult_type = this.consultTypeList.find(
        (element) => element.key == key
      )
      this.stepData = {
        id: Math.floor(Math.random() * 100) * -1,
        status: 1,
        notifications: [],
        step_type_id: type.id,
        step_type: type,
        medical_order_id: this._isStepInProcess(key)
          ? this.elementData?.arl_code_vigence?.id || null
          : null,
        case_date: {
          consult_type_id: consult_type.id,
          case_id: this.elementData.id,
          end_date: '',
          description: '',
          doctor_id: null,
          start_date: '',
          type_id: this.getDateTypeKey(key).id,
        },
      }
      this.setActionsByType()
      this.$root.$emit('bv::show::modal', 'modal-step')
    },
    deactivateStep(id) {
      const element = this.elementData.process.steps.find(
        (element) => element.id == id
      )
      const index = this.elementData.process.steps.indexOf(element)
      element.status = 0
      this.elementData.process.steps.splice(index, 1, element)
    },
    getDateTypeKey(key) {
      return this.dateTypeList.find((element) => element.key == key)
    },
    reactivateStep(id) {
      const element = this.elementData.process.steps.find(
        (element) => element.id == id
      )
      const index = this.elementData.process.steps.indexOf(element)
      element.status = 1
      this.elementData.process.steps.splice(index, 1, element)
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-step')
    },
    resolveStatusVariant(status) {
      if (status > 0) return 'success'
      return 'warning'
    },
    resolveStatusMessage(status) {
      if (status > 0)
        return this.$t(`${this.resourcesElementName}.statusValue.active`)
      return this.$t(`${this.resourcesElementName}.statusValue.inactive`)
    },
    validate_step_complete(type) {
      const step = this.elementData.process.steps.find(
        (element) => element.step_type.key == type
      )
      return step?.step_status?.key === 'FINISHED'
    },
    validate_step_uncomplete(type) {
      const step = this.elementData.process.steps.find(
        (element) => element.step_type.key == type
      )
      return !!step
    },
    is_step_enabled(step_to_validate) {
      const close_status = [
        'ARL_CLOSURE',
        'LOST',
        'ADMIN_CLOSURE',
        'CLOSED_NOT_LOCATED',
        'CLOSED',
      ]
      if (close_status.includes(this.elementData.case_status.key)) {
        return step_to_validate == 'REOPEN_CASE'
      }
      if (step_to_validate === 'try_contact') {
        const steps = this.elementData.process.steps.filter(
          (element) => element.step_type.key == 'TRY_CONTACT'
        )
        this.isComplete =
          this.elementData?.process?.steps?.some(
            (step) => step.contact_status?.key === 'SUCCESSFUL'
          ) || false
        if (steps.length < 1) return true
        for (const step of steps) {
          if (step.step_status && step.step_status.key === 'SUCCESSFUL') {
            return false
          }
        }
        return true
      }
      if (step_to_validate === 'ARL_CLOSURE' || step_to_validate === 'LOST') {
        return (
          this.elementData.process.steps.length > 0 &&
          this.elementData.case_status.key != step_to_validate
        )
      }
      if (step_to_validate === 'ADMIN_CLOSURE') {
        return (
          this.elementData.process.steps.length > 0 &&
          this.elementData.case_status.key != step_to_validate
        )
      }
      if (this.elementData.case_status === 'NOT_LOCATED') return false
      if (
        this.elementData.process.steps.find(
          (element) => element.step_type.key == 'TRY_CONTACT'
        )
      ) {
        switch (step_to_validate) {
          case 'add_consent':
            return !this.validate_step_uncomplete('CONSENT')
          case 'add_first_date':
            return (
              this.validate_step_uncomplete('CONSENT') &&
              !this.validate_step_uncomplete('FIRST_DATE')
            )
          case 'add_first_follow':
            return (
              this.validate_step_complete('FIRST_DATE') &&
              !this.validate_step_uncomplete('FIRST_FOLLOW')
            )
          case 'add_second_follow':
            return (
              this.validate_step_complete('FIRST_FOLLOW') &&
              !this.validate_step_uncomplete('SECOND_FOLLOW')
            )
          case 'add_third_follow':
            return (
              this.validate_step_complete('SECOND_FOLLOW') &&
              !this.validate_step_uncomplete('THIRD_FOLLOW')
            )
          case 'add_fourth_follow':
            return (
              this.validate_step_complete('THIRD_FOLLOW') &&
              !this.validate_step_uncomplete('FOURTH_FOLLOW')
            )
          case 'add_additional':
            return this.validate_step_complete('FIRST_DATE')
          case 'add_evolution':
            return this.validate_step_complete('FIRST_DATE')
        }
      }
      return false
    },
    is_step_in_medical_orders(step_to_validate) {
      if (
        step_to_validate == 'ADDITIONAL' &&
        !this._isStepInProcess(step_to_validate)
      ) {
        return !!this.elementData?.control_auth_code
      } else {
        return (
          this.elementData.arl_code_vigence &&
          this.elementData.arl_code_vigence.next_consult.key ==
            step_to_validate &&
          !this.elementData.arl_code_vigence.steps.some(
            (step) => step.key == step_to_validate
          )
        )
      }
    },
    _isStepInProcess(is_step) {
      if (is_step == 'ADDITIONAL') {
        return this.elementData?.process?.steps.some(
          (step) => step.step_type.key === is_step
        )
      }
      return true
    },
  },
}
</script>

<style></style>
