const dbPromise = new Promise((resolve, reject) => {
  const request = indexedDB.open(process.env.VUE_APP_INDEXED_DB.split('-')[0], 1)

  request.onupgradeneeded = event => {
    const db = event.target.result
    if (!db.objectStoreNames.contains(process.env.VUE_APP_INDEXED_DB.split('-')[1])) {
      db.createObjectStore(process.env.VUE_APP_INDEXED_DB.split('-')[1], { keyPath: 'url' })
    }
  }

  request.onsuccess = event => {
    resolve(event.target.result)
  }

  request.onerror = event => {
    reject(event.target.error)
  }
})

export const saveIndexedDB = async (url, data) => {
  const db = await dbPromise
  const transaction = db.transaction([process.env.VUE_APP_INDEXED_DB.split('-')[1]], 'readwrite')
  const objectStore = transaction.objectStore(process.env.VUE_APP_INDEXED_DB.split('-')[1])
  return new Promise((resolve, reject) => {
    const expiryTime = new Date()
    expiryTime.setSeconds(expiryTime.getSeconds() + parseInt(process.env.VUE_APP_TIME_DATA, 10) || 3600)
    const request = objectStore.put({ url, ...data, expiry: expiryTime, updatedAt: new Date() })
    request.onsuccess = () => {
      resolve()
    }
    request.onerror = () => {
      reject(request.error)
    }
  })
}
export const cleanupExpiredData = async (url) => {
  const db = await dbPromise
  const transaction = db.transaction([process.env.VUE_APP_INDEXED_DB.split('-')[1]], 'readwrite')
  const objectStore = transaction.objectStore(process.env.VUE_APP_INDEXED_DB.split('-')[1])
  return new Promise((resolve, reject) => {
    const request = objectStore.openCursor()
    request.onsuccess = event => {
      const cursor = event.target.result
      if (cursor) {
        const data = cursor.value
        if (data.url !== url && new Date(data.expiry) < new Date()) {
          cursor.delete()
        }
        cursor.continue()
      } else {
        resolve()
      }
    }
    request.onerror = () => {
      reject(request.error)
    }
  })
}

export const getIndexedDB = async url => {
  const db = await dbPromise
  const transaction = db.transaction([process.env.VUE_APP_INDEXED_DB.split('-')[1]], 'readwrite')
  const objectStore = transaction.objectStore(process.env.VUE_APP_INDEXED_DB.split('-')[1])
  return new Promise((resolve, reject) => {
    const request = objectStore.get(url)
    request.onsuccess = () => {
      const data = request.result
      if (data) {
        const expiryTime = new Date()
        data.expiry = expiryTime.setSeconds(expiryTime.getSeconds() + parseInt(process.env.VUE_APP_TIME_DATA, 10) || 3600)
        const updateRequest = objectStore.put(data)
        updateRequest.onsuccess = () => {
          resolve(request.result)
        }
        updateRequest.onerror = () => {
          reject(updateRequest.error)
        }
      } else {
        resolve(null)
      }
    }
    request.onerror = () => {
      reject(request.error)
    }
  })
}

export { dbPromise }