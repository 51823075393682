<template>
  <b-form
    @submit.prevent="save"
  >
    <validation-observer
      ref="elementForm"
      #default="{ invalid }"
    >
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.section.main_information') }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row v-if="companyIs('ENTERPRISE')">
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              md="4"
              lg="4"
            >
              <!-- Media -->
              <b-media>
                <b-col
                  cols="4"
                  md="4"
                  class="text-center mb-1"
                >
                  <b-img
                    :src="logo"
                    text=""
                    variant="light-success"
                    class="border"
                    fluid
                  />
                </b-col>
                <div class="mb-1">
                  <div class="mb-1">
                    <b-button
                      variant="primary"
                      @click="$refs.refInputLogo.click()"
                    >
                      <input
                        ref="refInputLogo"
                        type="file"
                        class="d-none"
                        @change="handleLogoImage()"
                      >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.updateLogo') }}</span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                  <div class="mb-1">
                    <b-button
                      variant="danger"

                      @click="removeLogoImage()"
                    >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.removeLogo') }}</span>
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                </div>
              </b-media>
            </b-col>
            <b-col
              cols="6"
              md="6"
            >
              <b-form-group
                label-for="NIT"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.nit') }} empresa</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.nit')"
                  rules="required"
                >
                  <b-form-input
                    id="NIT"
                    v-model="elementData.nit"
                    maxlength="11"
                    pattern="^[0-9]*$"
                    :placeholder="$t(resourcesElementName + '.object.nit_placeholder')"
                    @blur="fetchElementNit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="elementData.name"
                    maxlength="120"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="uen"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.uen') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.uen')"
                >
                  <b-form-input
                    id="uen"
                    v-model="elementData.uen"
                    maxlength="6"
                    type="number"
                    max="999999"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row v-else>
            <b-col
              cols="4"
              md="4"
              lg="4"
            >
              <!-- Media -->
              <b-media>
                <b-col
                  cols="4"
                  md="4"
                  class="text-center mb-1"
                >
                  <b-img
                    :src="logo"
                    text=""
                    variant="light-success"
                    class="border"
                    fluid
                  />
                </b-col>
                <div class="mb-1">
                  <div class="mb-1">
                    <b-button
                      variant="primary"
                      @click="$refs.refInputLogo.click()"
                    >
                      <input
                        ref="refInputLogo"
                        type="file"
                        class="d-none"
                        @change="handleLogoImage()"
                      >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.updateLogo') }}</span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                  <div class="mb-1">
                    <b-button
                      variant="danger"

                      @click="removeLogoImage()"
                    >
                      <span class="d-none d-sm-inline">{{ $t(resourcesElementName + '.object.removeLogo') }}</span>
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                </div>
              </b-media>
            </b-col>
            <b-col
              cols="6"
              xl="6"
            >
              <b-form-group
                label-for="NIT"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.nit') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.nit')"
                  rules="required"
                >
                  <b-form-input
                    id="NIT"
                    v-model="elementData.nit"
                    maxlength="11"
                    pattern="^[0-9]*$"
                    :placeholder="$t(resourcesElementName + '.object.nit_placeholder')"
                    @blur="fetchElementNit"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="name"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.name') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.name')"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="elementData.name"
                    maxlength="120"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="rips_code"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.rips_code') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.rips_code')"
                >
                  <b-form-input
                    id="rips_code"
                    v-model="elementData.rips_code"
                    maxlength="15"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="companyIs('SUPPLIER')"
                label-for="supplier_type"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.supplier_type') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.supplier_type')"
                >
                  <v-select
                    v-model="elementData.supplier_type_id"
                    :options="supplierTypeList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(supplierTypeList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>

          </b-row>
          <b-row v-if="companyIs('ENTERPRISE')">
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="address"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.address') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.address')"
                >
                  <b-form-input
                    id="address"
                    v-model="elementData.address"
                    maxlength="120"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.department') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.department')"
                >
                  <v-select
                    v-model="elementData.department_id"
                    :options="departmentList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(departmentList)"
                    @input="onDepartmentChange()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.city') }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.city')"
                >
                  <v-select
                    v-model="elementData.city_id"
                    :options="cityList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(cityList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <!-- Right Col: Table -->
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="address"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.address') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.address')"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="elementData.address"
                    maxlength="120"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="department"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.department') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.department')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.department_id"
                    :options="departmentList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(departmentList)"
                    @input="onDepartmentChange()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              md="4"
            >
              <b-form-group
                label-for="city"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.city') }}</span><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.city')"
                  rules="required"
                >
                  <v-select
                    v-model="elementData.city_id"
                    :options="cityList"
                    label="value"
                    value="id"
                    :reduce="option => option.id"
                    :placeholder="getPlaceholderValue(cityList)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
          label-for="billing-emails"
          >
            <feather-icon
              icon="MailIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">{{ $t(resourcesElementName + '.object.billing_emails') }}</span>
            <validation-provider
              #default="{ errors }"
              :name="$t(resourcesElementName + '.object.billing_emails')"
              rules="customEmails"
            >
              <b-form-input
                id="billing-emails"
                v-model="elementData.billing_emails"
                :placeholder="$t(resourcesElementName + '.object.billing_emails_placeholder')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-row>
            <b-col
              cols="4"
              md="4"
              lg="4"
            >
              <div class="text-center">
                <!-- Field: Status -->
                <br><br>
                <b-form-group
                  label-for="user-active"
                >
                  <b-form-checkbox
                    v-model="elementData.status"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    switch
                    inline
                    value="1"
                    unchecked-value="0"
                  />
                  <h2 class="inline">
                    <b-badge
                      pill
                      :variant="`light-${resolveStatusVariant(elementData.status)}`"
                      class="text-capitalize mr-0 mt-50"
                    >
                      {{ resolveStatusMessage(elementData.status) }}
                    </b-badge>
                  </h2>
                </b-form-group>
              </div>
            </b-col>
            <b-col
              v-if="companyIs('SUPPLIER') && elementData.status==1"
              cols="4"
              md="4"
              lg="4"
            >
              <date-picker
                :element-required="true"
                element-field="activation_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.activation_date')"
              />
            </b-col>
            <b-col
              v-if="companyIs('SUPPLIER') && elementData.status==0"
              cols="4"
              md="4"
              lg="4"
            >
              <date-picker
                :element-required="false"
                element-field="inactivation_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.inactivation_date')"
              />
            </b-col>
            <b-col
              v-if="companyIs('SUPPLIER')"
              cols="4"
              md="4"
              lg="4"
            >
              <date-picker
                :element-required="true"
                element-field="contract_end_date"
                :root-element-data="elementData"
                :element-label="$t(resourcesElementName + '.object.contract_end_date')"
              />
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>
      <b-row class="d-flex">
        <b-col
          cols="12"
          md="12"
        >
          <template v-if="elementData">
            <contacts-edit-tab
              :new-element="newElement"
              :element-data="elementData"
              :contact-type-list="contactTypeList"
              :department-list="departmentList"
              :city-list="cityContactList"
              class="mt-2 pt-75"
            />
          </template>
        </b-col>
      </b-row>
      <b-row class="d-flex">
        <b-col
          cols="12"
          md="12"
        >
          <template v-if="elementData">
            <guardians-edit-tab
              :new-element="newElement"
              :element-data="elementData"
              class="mt-2 pt-75"
            />
          </template>
        </b-col>
      </b-row>
      <b-row
        v-if="companyNotIs('ARL')"
        class="d-flex"
      >
        <b-col
          cols="12"
          md="12"
        >
          <template v-if="elementData">
            <headquarters-edit-tab
              :new-element="newElement"
              :element-data="elementData"
              :department-list="departmentList"
              :city-list="cityHeadquarterList"
              :contact-type-list="contactTypeList"
              :city-contact-list="cityContactList"
              class="mt-2 pt-75"
            />
          </template>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-end">
        <b-button
          v-if="!isModal"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          v-else
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="hideModal()"
        >
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid"
        >
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BImg, BMedia, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import GuardiansEditTab from '@/views/company/edit/EditGuardians.vue'
import HeadquartersEditTab from '@/views/company/edit/EditHeadquarters.vue'
import ContactsEditTab from '@/views/core/contact/EditContacts.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'
import { extend } from 'vee-validate';

extend('customEmails', {
  validate(value) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) return true;
    const emails = value.split(';').map(email => email.trim());
    return emails.every(email => emailPattern.test(email));
  },
  message: 'Por favor ingrese un correos validos separados por punto y coma',
});

export default {
  components: {
    DatePicker, HeadquartersEditTab, ContactsEditTab, GuardiansEditTab, BImg, BMedia, BCard, BButton, BRow, BCol, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, ValidationProvider, ValidationObserver, BFormCheckbox, vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    isModal: {
      type: Boolean,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    moduleName: {
      type: String,
      required: true,
    },
    supplierTypeList: {
      type: Array,
      required: true,
    },
    departmentList: {
      type: Array,
      required: true,
    },
    cityList: {
      type: Array,
      required: true,
    },
    cityContactList: {
      type: Array,
      required: true,
    },
    cityHeadquarterList: {
      type: Array,
      required: true,
    },
    elementLogo: {
      type: String,
      required: true,
    },
    contactTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      logoUpdated: false,
      logo: null,
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  methods: {
    router() {
      return router
    },
    companyNotIs(type) {
      console.log(this.elementData.company_type_key)
      return this.elementData.company_type_key != type
    },
    companyIs(type) {
      return this.elementData.company_type_key == type || (this.elementData.company_type && this.elementData.company_type.key == type)
    },
    resolveStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveStatusMessage(status) {
      if (status == 0) return this.$t('user.statusValue.inactive')
      return this.$t('user.statusValue.active')
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-company')
    },
    save() {
      if (this.elementData.contract_end_date && (new Date(this.elementData.contract_end_date) < new Date(this.elementData.activation_date))) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t(`${this.resourcesElementName}.errors.title`),
            icon: 'SaveIcon',
            variant: 'danger',
            text: this.$t(`${this.resourcesElementName}.errors.date_comparison_contract`),
          },
        })
        return
      }
      if (this.elementData.inactivation_date && (new Date(this.elementData.inactivation_date) < new Date(this.elementData.activation_date))) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t(`${this.resourcesElementName}.errors.title`),
            icon: 'SaveIcon',
            variant: 'danger',
            text: this.$t(`${this.resourcesElementName}.errors.date_comparison`),
          },
        })
        return
      }
      if (this.isModal) {
        this.$refs.elementForm.validate().then(success => {
          if (success) {
            this.$root.$emit('save-company', this.elementData)
            this.$root.$emit('bv::hide::modal', 'modal-company')
          }
        })
      } else {
        this.$refs.elementForm.validate().then(success => {
          if (success) {
            if (this.logo && this.logoUpdated) {
              this.elementData.logo = this.logo
            }
            this.$parent.save(this.elementData)
          }
        })
      }
    },
    onDepartmentChange() {
      console.info('onDepartmentChange')
      this.elementData.city_id = null
      this.$root.$emit('fetch-cities-company', this.elementData.department_id)
    },
    handleLogoImage() {
      this.createLogoBase64mage(this.$refs.refInputLogo.files[0])
      this.logoUpdated = true
    },
    createLogoBase64mage(logo) {
      const reader = new FileReader()
      reader.onload = e => {
        this.logo = e.target.result
      }
      reader.readAsDataURL(logo)
    },
    removeLogoImage() {
      this.logoUpdated = true
      this.logo = ''
      this.elementData.logo_id = null
      this.$refs.refInputLogo.value = ''
    },
    fetchElementNit() {
      // this.newElement = false
      console.info('fetchElementNit')
      if (this.elementData.nit) {
        this.$root.$emit('fetch-element-nit', this.elementData.nit)
      }
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
  validations: {
  customEmails: {
    validate(value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value) return true;
      const emails = value.split(';').map(email => email.trim());
      return emails.every(email => emailPattern.test(email));
    },
    message: 'Por favor ingrese un correos validos separados por punto y coma',
  },
},
}
</script>

<style>

</style>
