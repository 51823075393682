var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-body',[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[(_vm.has_actions())?[(_vm.has_action('add_date'))?_c('div',[_c('table',[_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"BookOpenIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.date.doctor_name")))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.get_doctor(_vm.elementData.case_date.doctor_id))+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"BookOpenIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.date.start_date")))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.elementData.case_date, "start_date"))+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"BookOpenIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.date.end_date")))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.elementData.case_date, "end_date"))+" ")])])]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.editDate}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.edit_date")))])])],1):_vm._e(),(_vm.has_action('register_try_contact'))?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"contact"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.contact")))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.contact'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.contactList,"label":"data","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.change_contact},model:{value:(_vm.elementData.contact_id),callback:function ($$v) {_vm.$set(_vm.elementData, "contact_id", $$v)},expression:"elementData.contact_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"contact_status"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + ".object.contact_status")))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.contact_status'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.contactStatusList,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},on:{"input":_vm.change_status_contact},model:{value:(_vm.elementData.contact_status_id),callback:function ($$v) {_vm.$set(_vm.elementData, "contact_status_id", $$v)},expression:"elementData.contact_status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e()]:_vm._e(),(_vm.has_action('notification'))?_c('div',{staticClass:"d-flex"},[(_vm.elementData)?_c('notifications-edit-tab',{staticClass:"mt-2 pt-75",attrs:{"new-element":_vm.newElement,"element-data":_vm.elementData,"notification-config-list":_vm.notificationConfigList,"notification-medium-list":_vm.notificationMediumList,"contact-list":_vm.contactList}}):_vm._e()],1):_vm._e(),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){return _vm.hideModal()}}},[_vm._v(" "+_vm._s(_vm.$t("actions.back"))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t("actions.save"))+" ")])],1)],2)]}}])}),_c('div',[_c('b-modal',{attrs:{"id":"modal-date","size":"xl","no-close-on-esc":"","no-close-on-backdrop":"","no-modal-header-close":"","hide-footer":"","title":_vm.$t(_vm.resourcesElementName + '.object.edit_calendar_title')},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.close-confirmation",modifiers:{"close-confirmation":true}}],attrs:{"size":"sm","variant":"outline-danger"}},[_vm._v(" Cancelar ")]),_c('h5',[_vm._v(" "+_vm._s(_vm.$t(_vm.resourcesElementName + ".object.edit_calendar_title"))+" ")])]}}])},[[_c('calendar-edit-tab',{staticClass:"mt-2 pt-75",attrs:{"element-data":_vm.elementData.case_date}})]],2),_c('b-modal',{attrs:{"id":"close-confirmation","size":"sm","title":"Confirmación","hide-footer":""}},[[_c('p',{staticClass:"my-1"},[_vm._v("Si cierra el diálogo perderá los cambios")]),_c('p',[_vm._v("¿Desea continuar?")]),_c('b-button',{attrs:{"size":"mb-1 mb-sm-0 mr-0 mr-sm-1","variant":"danger"},on:{"click":function($event){return _vm.reset_changes()}}},[_vm._v(" Aceptar ")]),_c('b-button',{attrs:{"size":"mb-1 mb-sm-0 mr-0 mr-sm-1","variant":"primary"},on:{"click":function($event){return _vm.close_confirmation()}}},[_vm._v(" Cancelar ")])]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }