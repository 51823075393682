const handlerUpdateObjectById = (current_object, update_object) => {
  // Crear un mapa de id -> índice para el current_object para búsquedas rápidas
  const object = [...current_object]
  const originalMap = new Map(current_object.map(({ id }, index) => [id, index]))

  update_object.forEach(update => {
    const objIndex = originalMap.get(update.id)
    if (objIndex !== undefined) {
      object[objIndex] = update
    } else {
      object.push(update)
    }
  })
  return object
}

export default handlerUpdateObjectById
